export function cases(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_CASES_SUCCESS':
      return action.cases;
    default:
      return state;
  }
}
export function acase(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_CASE_SUCCESS':
      return action.acase;
    default:
      return state;
  }
}
export function sources(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_SOURCES_SUCCESS':
      return action.sources;
    default:
      return state;
  }
}
export function client(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_CLIENT_SUCCESS':
      return action.client;
    case 'NAEVO_CHANGE_CLIENT_STATE_SUCCESS':
      return action.client;
    case 'NAEVO_UPDATE_CLIENT_SUCCESS':
      return action.client;
    default:
      return state;
  }
}

export function articles(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_ARTICLES_SUCCESS':
      return action.articles;
    default:
      return state;
  }
}

export function allArticles(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_ALL_ARTICLES_SUCCESS':
      return action.allArticles;
    default:
      return state;
  }
}

export function installers(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_INSTALLERS_SUCCESS':
      return action.installers;
    default:
      return state;
  }
}

export function partners(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_PARTNERS_SUCCESS':
      return action.partners;
    default:
      return state;
  }
}

export function overview(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_OVERVIEW_BY_PARTNER_SUCCESS':
      return action.overview;
    case 'NAEVO_GET_OVERVIEW_BY_PARTNER_AND_INSTALLER_SUCCESS':
      return action.overview;
    default:
      return state;
  }
}

export function overviewregion(state = [], action) {
  switch (action.type) {
    case 'NAEVO_GET_OVERVIEW_BY_REGION_SUCCESS':
      return action.overviewregion;
    default:
      return state;
  }
}

export function summary(state = [], action) {
  switch (action.type) {
    case 'ADD_TO_SUMMARY_SUCCESS':
      return action.summary;
    default:
      return state;
  }
}

export function resting(state = [], action) {
  switch (action.type) {
    case 'NAEVO_SET_CASE_RESTING':
      return action.resting;
    default:
      return state;
  }
}

export function cancelled(state = [], action) {
  switch (action.type) {
    case 'NAEVO_SET_CASE_CANCELLED':
      return action.cancelled;
    default:
      return state;
  }
}

export function kbs(state = [], action) {
  switch (action.type) {
    case 'NAEVO_SET_CASE_KBS':
      return action.kbs;
    default:
      return state;
  }
}

export function comment(state = [], action) {
  switch (action.type) {
    case 'NAEVO_POST_COMMENT_SUCCESS':
      return action.comment;
    default:
      return state;
  }
}

export function internalComment(state = [], action) {
  switch (action.type) {
    case 'NAEVO_POST_INTERNAL_COMMENT_SUCCESS':
      return action.comment;
    default:
      return state;
  }
}

export function booked(state = [], action) {
  switch (action.type) {
    case 'NAEVO_BOOK_CASE_SUCCESS':
      return action.booked;
    default:
      return state;
  }
}

export function tax(state = [], action) {
  switch (action.type) {
    case 'NAEVO_CHECK_TAX_DEDUCTION_SUCCESS':
      return action.tax;
    case 'NAEVO_UPDATE_TAX_DEDUCTION_SUCCESS':
      return action.tax;
    default:
      return state;
  }
}

export function sms(state = [], action) {
  switch (action.type) {
    case 'SEND_PARTNER_SMS_SUCCESS':
      return action.sms;
    case 'SEND_EMPLOYEE_SMS_SUCCESS':
      return action.sms;
    default:
      return state;
  }
}
