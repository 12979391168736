import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SummaryStepTwoComponent(props) {
  const { t } = useTranslation();
  return (
    <div>
      {props.steptwo.length > 0 && (
        <span>
          <fieldset>
            <legend>
              {/* <Trans>Information</Trans> */}
              {t('Information')}
            </legend>
            {props.steptwo.map((s2, i) => (
              <span key={i}>
                <p>
                  <strong style={{ textTransform: 'uppercase' }}>{s2.qtext}</strong>: {s2.atext}
                </p>
              </span>
            ))}
          </fieldset>
        </span>
      )}
    </div>
  );
}
