import React from 'react';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import SummaryStepOneComponent from './SummaryStepOneComponent';
import SummaryStepTwoComponent from './SummaryStepTwoComponent';
import SummaryStepThreeComponent from './SummaryStepThreeComponent';

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});
function SummaryAsideComponent(props) {
  const { classes } = props;
  return (
    <div>
      <form className="k-form">
        <Paper className={classes.paper}>
          {props.stepone && props.client[0] && parseInt(props.stepone.clientId, 10) === props.client[0].id && <SummaryStepOneComponent stepone={props.stepone} />}
          {props.steptwo && props.case && props.steptwo.length > 0 && props.steptwo[0].case_id === props.case.id && <SummaryStepTwoComponent steptwo={props.steptwo} />}
          {props.stepthree && props.case && props.stepthree.caseid === props.case.id && <SummaryStepThreeComponent stepthree={props.stepthree} />}
        </Paper>
      </form>
    </div>
  );
}

export default withStyles(styles)(SummaryAsideComponent);
