export function loadFirstStepForm(state = {}, action) {
  switch (action.type) {
    case 'LOAD_FIRST_STEP_FORM':
      return {
        data: action.data,
      };
    default:
      return state;
  }
}

export function step(state = {}, action) {
  switch (action.type) {
    case 'STEPPER_CHANGE_SUCCESS':
      return action.step;
    default:
      return state;
  }
}

export function stepone(state = {}, action) {
  switch (action.type) {
    case 'SET_STEP_ONE_SUCCESS':
      return action.stepone;
    default:
      return state;
  }
}
export function steptwo(state = {}, action) {
  switch (action.type) {
    case 'SET_STEP_TWO_SUCCESS':
      return action.steptwo;
    default:
      return state;
  }
}
export function stepthree(state = {}, action) {
  switch (action.type) {
    case 'SET_STEP_THREE_SUCCESS':
      return action.stepthree;
    default:
      return state;
  }
}
export function ctname(state = {}, action) {
  switch (action.type) {
    case 'CASETYPE_NAME_SUCCESS':
      return action.ctname;
    default:
      return state;
  }
}
export function sourcename(state = {}, action) {
  switch (action.type) {
    case 'SOURCE_NAME_SUCCESS':
      return action.sourcename;
    default:
      return state;
  }
}

export function log(state = {}, action) {
  switch (action.type) {
    case 'ADD_NEW_LOG_SUCCESS':
      return action.log;
    default:
      return state;
  }
}

export function qa(state = {}, action) {
  switch (action.type) {
    case 'GET_ANSWERED_QUESTIONS':
      return action.answers;
    default:
      return state;
  }
}
