import React from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
export default function NumericFieldComponent(props) {
  const selected = props.selected.find(el => el.question === props.question.question_id);
  var qanswer = props.answers ? props.answers.find(el => el.question_id == props.question.question_id) : null;

  return (
    <div>
      <label htmlFor={'numeric' + props.question.question_id} style={{ fontSize: '18px' }} />
      <NumericTextBox
        defaultValue={0}
        name={'numeric' + props.question.question_id}
        id={'numeric' + props.question.question_id}
        onChange={props.numChange}
        data-qid={props.question.question_id}
        data-qtext={props.question.question_text}
        data-articleid={props.question.articleId}
        value={selected ? +selected.atext : 0}
        data-aid={qanswer ? qanswer.answer_id : null}
      />
    </div>
  );
}
