import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  card: {
    minWidth: 275,
    background: 'lightgrey',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    background: '#C24641',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  redText: {
    color: 'red',
  },
  bar: {
    background: '#FF5043',
  },
});
function ReservedComponent(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const calculateSlotSize = estimate => {
    const base = 25;
    // const add = 25;
    const calc = +estimate / base;
    // const newSize = estimate > base ? base + calc * add : 36;
    const newSize = estimate > 60 ? calc * base : base;
    return { height: newSize + 'px', marginTop: '4px' };
  };
  return (
    <div>
      <Paper
        className={classes.paper}
        // style={calculateSlotSize(props.reservation.minutes)}
        style={{ height: '25px', marginTop: '4px' }}
      >
        <Grid container spacing={8}>
          <Grid item md={4} style={{ textAlign: 'left' }}>
            <strong>{t('RESERVED')}</strong>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center' }}>
            <strong>{props.reservation.comment}</strong>
          </Grid>
          <Grid item md={5} style={{ textAlign: 'right' }}>
            {props.reservation.minutes > 0 && <strong>{props.reservation.minutes}</strong>}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
ReservedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReservedComponent);
