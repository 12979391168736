import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Input } from '@progress/kendo-react-inputs';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  chip: {
    margin: theme.spacing.unit,
  },
});
class InstallersListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: '',
      partners: this.props.partners,
      installers: this.props.installers,
    };
  }

  componentDidUpdate() {
    // if (this.props.partners && this.state.partners.length === 0) {
    //   this.setState({
    //     partners: this.props.partners,
    //   });
    // }
    if (this.props.installers && this.state.installers.length === 0) {
      this.setState({
        installers: this.props.installers,
      });
    }
  }

  handleListItemClick = (event, index, partnerid, installerid) => {
    this.setState({ selectedIndex: index });
    this.props.getOverview(partnerid, installerid);
  };

  searchHandler = event => {
    let query = event.target.value.toLowerCase();
    let installers = this.props.installers.filter(el => {
      let searchValue = el.name.toLowerCase();
      return searchValue.indexOf(query) !== -1;
    });
    this.setState({
      installers: installers,
    });
  };
  render() {
    // const { classes } = this.props;
    return (
      <div>
        <fieldset>
          <legend>
            <Trans>All installers</Trans>
          </legend>
          <Input label="Search" style={{ width: '100%' }} onChange={this.searchHandler} />
          <div style={{ height: '500px', overflow: 'scroll' }}>
            {/* <input type="text" name="" id="" /> */}
            <List component="nav">
              {this.state.installers.map((installer, i) => (
                <span key={i}>
                  <ListItem
                    button
                    selected={this.state.selectedIndex === i}
                    onClick={e => this.handleListItemClick(e, i, installer.partnerId, installer.id)}
                  >
                    <ListItemText primary={installer.name} />
                  </ListItem>
                </span>
              ))}
            </List>
          </div>
        </fieldset>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    partners: state.partners,
    installers: state.installers,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(InstallersListComponent)
  )
);
