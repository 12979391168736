import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Stepper from './Stepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';

import logo from '../Inselo Logo white.png';

const styles = {
  root: {
    flexGrow: 1,
  },
  bar: {
    background: '#FF5043',
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10,
  },
};
function Header(props) {
  const { classes } = props;

  const popOut = () => {
    props.glContainer.layoutManager.root.contentItems[0].addChild(props.screen2);
    props.glContainer.layoutManager.root.contentItems[0].contentItems[1].popout();
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.bar} position="static">
        <Toolbar variant="dense">
          {/* <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" color="inherit">
            <img src={logo} alt="inselo-logo" width="120px" />
          </Typography>
          <Stepper step={props.step} goto={props.goto} />
          <FontAwesomeIcon icon={faDesktop} size="lg" onClick={popOut} />
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
