import React from 'react';

export default function RadioComponent(props) {
  const selected = props.selected.find(el => el.question === props.question.question_id);
  return (
    <span>
      {props.answers.map((a, i) => (
        <span key={i}>
          {a.question_id === props.question.question_id && (
            <span>
              <input
                type="radio"
                name={'radio' + a.answer_id}
                id={'radio' + a.answer_id}
                data-qid={props.question.question_id}
                data-aid={a.answer_id}
                data-script={a.script}
                data-qtext={props.question.question_text}
                data-atext={a.text}
                data-articleid={a.articleId}
                className="k-radio"
                checked={selected ? (selected.answer === a.answer_id ? true : false) : false}
                onChange={props.radioChange}
              />
              <label htmlFor={'radio' + a.answer_id} className="k-radio-label">
                <strong>{a.text}</strong>
              </label>
            </span>
          )}
        </span>
      ))}
    </span>
  );
}
