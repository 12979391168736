import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@progress/kendo-react-buttons';
import { naevoSendEmployeeSMS } from '../../../actions/naevo';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getStateColor } from '../../../functions';
import * as moment from 'moment';

const styles = theme => ({
  card: {
    minWidth: 275,
    background: 'lightgrey',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    // border: '2px dashed lightgrey',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  redText: {
    color: 'red',
  },
  bar: {
    background: '#FF5043',
  },
});

function EmptySlotComponent(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const estimate = props.checkSlotAvailability(props.day, props.slot);
  const isVisibleKBS = false;

  const isKBS = e => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    // const conf = confirm('Are you sure case is KBS?');
    // if (conf) {
    props.saveBookingKbs(props.employee);
    // }
  };

  // const visibleKBS = () => {
  //   const partnerid = props.overview.map(e => e.partnerId);
  //   console.log(partnerid);
  //   console.log('testerrrr');
  //   props.geo.details.map(detail => {
  //     if (detail.partner) {
  //       isVisibleKBS = detail.partner.custom_fields.kbs > 0 ? true : false;
  //     } else {
  //       isVisibleKBS = true;
  //     }
  //   });
  // };

  const calculateSlotSize = estimate => {
    const base = 25;
    // const add = 25;
    const calc = +estimate / base;
    // const newSize = estimate > base ? base + calc * add : 36;
    const newSize = estimate > 60 ? calc * base : base;
    return { height: newSize + 'px', marginTop: '4px' };
  };

  const sendSMS = async () => {
    return await props.sendEmployeeSMS(props.employee);
  }

  const sendEmployeeSMS = () => {
    sendSMS().then((response) => {
      if (response.sms) {
        toast.success(response.sms);
      }
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error!")
      }
    });
  }

  const border = () => {
    if (props.acase.installerId === props.employee && moment(props.acase.installDate).isSame(moment(props.day.date).format("YYYY-MM-DD")) && props.slot.slot === props.acase.installTime)
      return '3px solid #3f51b5';

    return '2px dashed lightgrey';
  }

  // visibleKBS();
  return (
    <div>
      <Paper
        className={classes.paper}
        elevation={0}
        // style={calculateSlotSize(estimate.estimate)}
        style={{ 'min-height': '25px', 'max-height': '100px', marginTop: '4px', background: 'transparent', border: border() }}
      >
        <Grid container spacing={8}>
        <Grid item md={1} style={{ textAlign: 'left' }}>
            <strong style={{ color: getStateColor(props.case.state) }}>{props.case.stateName}</strong>
          </Grid>
          <Grid item md={1} style={{ textAlign: 'left' }}>
            <strong>{props.case.sourceCaseTypeName}</strong>
          </Grid>
          <Grid item md={1} style={{ textAlign: 'center' }}>
            <strong>{props.sourcename}</strong>
          </Grid>
          <Grid item md={1} style={{ textAlign: 'center' }}>
            <strong>{props.case.id}</strong>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center' }}>
            {props.client.map((el, i) => (
              <span key={i}>
                <strong>{el.installAddressZip}</strong>&nbsp;&nbsp;<strong>{el.installAddressCity}</strong>
              </span>
            ))}
          </Grid>
          <Grid item md={4} style={{ textAlign: 'right', marginTop: '-5px' }}>
            <Button type={"button"} type="button" onClick={sendEmployeeSMS}>
              <FontAwesomeIcon icon={faMobileAlt} />
            </Button>
            &nbsp;&nbsp;
            {/* {isVisibleKBS === true && ( */}
            <Button secondary="true" onClick={isKBS}>
              KBS
            </Button>
            {/* )} */}
            &nbsp;&nbsp;
            {estimate.full && (
              <span>
                <span>
                  <Button primary={true} type="button" onClick={() => props.saveBookingDetails(props.day, props.slot, props.employee)}>
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'orange' }} />
                    &nbsp;&nbsp;
                    {t('Book')}
                  </Button>
                </span>
              </span>
            )}
            {!estimate.full && (
              <Button primary={true} type="button" onClick={() => props.saveBookingDetails(props.day, props.slot, props.employee)}>
                {t('Book')}
              </Button>
            )}
          </Grid>
          <Grid item md={1} style={{ textAlign: 'right' }}>
            <strong>{estimate.estimate}</strong>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

EmptySlotComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendEmployeeSMS: (employeeId) => dispatch(naevoSendEmployeeSMS(employeeId)),
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EmptySlotComponent));
