import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
// import PropTypes from 'prop-types';

import CaseComponent from '../Case/CaseComponent';
import CustomerInfoComponent from '../CustomerInfo/CustomerInfoComponent';
import ContactInfoComponent from '../ContactInfo/ContactInfoComponent';
import BookingComponent from '../Booking/BookingComponent';

import * as Moment from 'moment';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});
class InfoComponent extends Component {
  async getConfig() {
    await this.props.glContainer;
  }
  componentDidMount() {
    this.getConfig().then(() => {
      // console.log(this.props.glContainer.parent.parent.popout());
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className="k-form">
          <Paper className={classes.paper}>
            <CaseComponent />
            <hr />
            <CustomerInfoComponent countrycode={this.props.match.params.countrycode} formatPersonIdFromSeToNoStandart={this.formatPersonIdFromSeToNoStandart} formatPersonIdFromSeToDkStandart={this.formatPersonIdFromSeToDkStandart} />
            <hr />
            <ContactInfoComponent />
            {/* <BookingComponent /> */}
          </Paper>
        </form>
      </div>
    );
  }

  formatPersonIdFromSeToNoStandart = value => {
    if (this.isValidPersonId(value, 'se')) {
      var day = value.substring(6, 8);
      var month = value.substring(4, 6);
      var year = value.substring(0, 4);
      return day + month + year;
    }

    return value;
  }

  formatPersonIdFromSeToDkStandart = value => {
    if (this.isValidPersonId(value, 'se')) {
      var day = value.substring(6, 8);
      var month = value.substring(4, 6);
      var year = value.substring(0, 4);
      return day + month + year;
    }

    return value;
  }

  isValidPersonId = (personId, country) => {
    return Moment(personId, country === 'se' ? "YYYYMMDD" : country === 'no' ? "DDMMYYYY" : "DDMMYYYY", true).isValid();
  }
}

export default withStyles(styles)(InfoComponent);
