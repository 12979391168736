import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { MultiViewCalendar } from '@progress/kendo-react-dateinputs';
import { withTranslation, Trans } from 'react-i18next';

import './CancelDialog.css';
import { Grid } from '@material-ui/core';

export class CancelDialog extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    allAnswered: false,
    comment: undefined
  };

  closeDialog = () => {
    var caseObj = this.props.acase;

    if (!caseObj || !caseObj[0]) {
      this.props.toggleDialog();
      return;
    }

    this.clearQuestions();

    this.props.toggleDialog();
  }

  componentDidMount() {
    this.setState({
      allAnswered: this.allQuestionsAnswered(this.props.cancelReason)
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.cancelReason !== prevProps.cancelReason) {
      this.clearQuestions();
      this.setState({
        allAnswered: false
      })
    }
  }

  setComment = (e) => {
    this.setState({
      comment: e.target.value
    })
  };

  clearQuestions = () => {
    var caseObj = this.props.acase

    if (caseObj.cancelFeedbackQuestions) {
      caseObj.cancelFeedbackQuestions.forEach(element =>
        element.answers = element.answers.map(a => {
          a.selected = false;
          return a;
        })
      );
    }

    if (caseObj.restingFeedbackQuestions) {
      caseObj.restingFeedbackQuestions.forEach(element =>
        element.answers = element.answers.map(a => {
          a.selected = false;
          return a;
        })
      );
    }
  }

  cancelBooking = () => {
    const { comment } = this.state;
    const { cancelReason, cancelBooking } = this.props;

    if (cancelReason === 'resting' || cancelReason === "cancelled") {
      cancelBooking({
        comment: comment,
        answerIds: this.getQuestionAnswerIds(cancelReason),
        employeeId: localStorage.getItem('naevoUser')
      });
    } else {
      cancelBooking();
    }
  };

  getQuestionAnswerIds = (cancelReason) => {
    var caseObj = this.props.acase;
    var result = [];

    if (!caseObj) {
      return result;
    }

    if (cancelReason === 'resting') {
      caseObj.restingFeedbackQuestions.forEach(element => {
        var answers = element.answers.filter(a => a.selected).map(p => p.id);
        result = result.concat(answers);
      });
    } else {
      caseObj.cancelFeedbackQuestions.forEach(element => {
        var answers = element.answers.filter(a => a.selected).map(p => p.id);
        result = result.concat(answers);
      });
    }

    console.log(result);

    return result;
  };

  handleCancelQuestionChange = (cancelReason, question, answer, e) => {
    debugger;
    if (question.multiChoice) {
      answer.selected = e.target.checked;
    } else {
      question.answers.forEach(ans => {
        ans.selected = false;
      });

      answer.selected = true;
    }

    this.setState({
      allAnswered: this.allQuestionsAnswered(cancelReason)
    });
  };

  allQuestionsAnswered = (cancelReason) => {
    var caseObj = this.props.acase;

    if (cancelReason === 'resting') {
      if (!caseObj || !caseObj || !caseObj.restingFeedbackQuestions || caseObj.restingFeedbackQuestions.length === 0) {
        return true;
      }

      return !caseObj.restingFeedbackQuestions.find(q => q.obligatory && !q.answers.find(a => a.selected));

    } else {
      if (!caseObj || !caseObj || !caseObj.cancelFeedbackQuestions || caseObj.cancelFeedbackQuestions.length === 0) {
        return true;
      }

      return !caseObj.cancelFeedbackQuestions.find(q => q.obligatory && !q.answers.find(a => a.selected));
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <Dialog title={t('Cancel Booking')} onClose={this.closeDialog}>
          <Grid container spacing={8}>
            <Grid item md={12}>
              <p style={{ margin: '25px' }}>{t('Reason for cancelling booking')}?</p>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item md={12}>
              <input
                type="radio"
                name="rResting"
                value="resting"
                id="rResting"
                className="k-radio"
                checked={this.props.cancelReason === 'resting'}
                onChange={this.props.change}
              />

              <label className="k-radio-label" htmlFor="rResting">
                <strong>{t('Resting')}.</strong>&nbsp;<span>{t('Booking cant be done at this time')}</span>
              </label>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={8}>
            <Grid item md={12}>
              <input
                type="radio"
                name="rCancelled"
                value="cancelled"
                id="rCancelled"
                className="k-radio"
                checked={this.props.cancelReason === 'cancelled'}
                onChange={this.props.change}
              />
              <label className="k-radio-label" htmlFor="rCancelled">
                <strong>{t('Cancelled')}.</strong>&nbsp;<span>{t('Customer do not need help')}</span>
              </label>
            </Grid>
          </Grid>
          <br />
          {(this.props.acase.state == 1 || this.props.acase.state == 2) && (<Grid container spacing={8}>
            <Grid item md={12}>
              <input
                type="radio"
                name="rPhoneCall1"
                value="phoneCall1"
                id="rPhoneCall1"
                className="k-radio"
                checked={this.props.cancelReason === 'phoneCall1'}
                onChange={this.props.change}
              />
              <label className="k-radio-label" htmlFor="rPhoneCall1">
                <strong>{t('Phone Call')}.</strong>
              </label>
            </Grid>
          </Grid>
          )}
          {this.props.acase.state == 36 && (<Grid container spacing={8}>
            <Grid item md={12}>
              <input
                type="radio"
                name="rPhoneCall2"
                value="phoneCall2"
                id="rPhoneCall2"
                className="k-radio"
                checked={this.props.cancelReason === 'phoneCall2'}
                onChange={this.props.change}
              />
              <label className="k-radio-label" htmlFor="rPhoneCall2">
                <strong>{t('Phone Call')}.</strong>
              </label>
            </Grid>
          </Grid>)}
          {(this.props.acase.state == 37 || this.props.acase.state == 39) && (<Grid container spacing={8}>
            <Grid item md={12}>
              <input
                type="radio"
                name="rPhoneCall3"
                value="phoneCall3"
                id="rPhoneCall3"
                className="k-radio"
                checked={this.props.cancelReason === 'phoneCall3'}
                onChange={this.props.change}
              />
              <label className="k-radio-label" htmlFor="rPhoneCall3">
                <strong>{t('Phone Call')}.</strong>
              </label>
            </Grid>
          </Grid>)}
          <br />
          {this.props.cancelReason === 'resting' && (
            <span>
              <hr />
              <MultiViewCalendar views={1} value={this.props.cancelDate} onChange={this.props.changeCancelDate} />
              <hr />
              {this.props.acase.restingFeedbackQuestions && (
                <div className="resting-questions-container">
                  {this.props.acase.restingFeedbackQuestions.map((question, i) => (
                    <div className="question" key={i}>
                      <legend>{question.question}</legend>
                      <span>
                        {question.answers.map((answer, i2) => (
                          <div key={i2}>
                            {question.multiChoice && (
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id={'radio' + answer.id}
                                  name={'radio' + question.id}
                                  className="k-checkbox"
                                  onChange={(e) => this.handleCancelQuestionChange('resting', question, answer, e)}
                                />
                                <label className="k-checkbox-label" htmlFor={'radio' + answer.id} style={{ fontSize: '14px' }}>
                                  <strong>{answer.answer}</strong>
                                </label>
                                <p>{answer.description}</p>
                              </React.Fragment>
                            )}
                            {!question.multiChoice && (
                              <React.Fragment>
                                <input
                                  type="radio"
                                  name={'radio' + question.id}
                                  id={'radio' + answer.id}
                                  className="k-radio"
                                  onChange={(e) => this.handleCancelQuestionChange('resting', question, answer, e)}
                                />
                                <label htmlFor={'radio' + answer.id} className="k-radio-label">
                                  <strong>{answer.answer}</strong>
                                </label>
                                <p>{answer.description}</p>
                              </React.Fragment>
                            )}
                          </div>
                        ))}
                      </span>
                    </div>
                  ))}
                </div>
              )}
              <textarea className="k-textarea" placeholder={t('Comment')} onChange={this.props.changeRestComment} style={{ width: '458px' }} />
            </span>
          )}
          {this.props.cancelReason === 'cancelled' && this.props.acase && this.props.acase.cancelFeedbackQuestions && (
            <div className="cancel-questions-container">
              {this.props.acase.cancelFeedbackQuestions.map((question, i) => (
                <div className="question" key={i}>
                  <legend>{question.question}</legend>
                  <span>
                    {question.answers.map((answer, i2) => (
                      <div key={i2}>
                        {question.multiChoice && (
                          <React.Fragment>
                            <input
                              type="checkbox"
                              id={'radio' + answer.id}
                              name={'radio' + question.id}
                              className="k-checkbox"
                              onChange={(e) => this.handleCancelQuestionChange('cancelled', question, answer, e)}
                            />
                            <label className="k-checkbox-label" htmlFor={'radio' + answer.id} style={{ fontSize: '14px' }}>
                              <strong>{answer.answer}</strong>
                            </label>
                            <p>{answer.description}</p>
                          </React.Fragment>
                        )}
                        {!question.multiChoice && (
                          <React.Fragment>
                            <input
                              type="radio"
                              name={'radio' + question.id}
                              id={'radio' + answer.id}
                              className="k-radio"
                              onChange={(e) => this.handleCancelQuestionChange('cancelled', question, answer, e)}
                            />
                            <label htmlFor={'radio' + answer.id} className="k-radio-label">
                              <strong>{answer.answer}</strong>
                            </label>
                            <p>{answer.description}</p>
                          </React.Fragment>
                        )}
                      </div>
                    ))}
                  </span>
                </div>
              ))}
            </div>
          )}
          {this.props.cancelReason === 'cancelled' && (
            <span>
              <hr />
              <textarea className="k-textarea" placeholder={t('Comment')} onChange={this.setComment} />
            </span>
          )}
          <DialogActionsBar>
            <div></div>
            <button className="k-button" onClick={this.closeDialog}>
              <Trans>Back</Trans>
            </button>
            <button className="k-button" onClick={this.cancelBooking} disabled={!this.state.allAnswered && (this.props.cancelReason === 'cancelled' || this.props.cancelReason === "resting")}>
              <span style={{ color: 'red' }}><Trans>Cancel Booking</Trans></span>
            </button>
          </DialogActionsBar>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(CancelDialog);