import auth0 from 'auth0-js';
import history from '../history';
import axios from 'axios';
import { getNaevoApiUrl, getNaevoApiToken } from '../functions';

class Auth {
  accessToken;
  idToken;
  expiresAt;
  userProfile;
  client =
    this.getCountryCodeFromPath() === 'no'
      ? process.env.REACT_APP_AUTH0_CLIENT_ID_NO
      : this.getCountryCodeFromPath() === 'se'
        ? process.env.REACT_APP_AUTH0_CLIENT_ID_SE
        : this.getCountryCodeFromPath() === 'dk'
          ? process.env.REACT_APP_AUTH0_CLIENT_ID_DK
          : '';

  // TODO: Get profile and check naevo API for email to determine valid user
  auth0 = new auth0.WebAuth({
    domain: 'slog.auth0.com',
    audience: 'bookingapi',
    clientID: this.client,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URL + '/' + this.getCountryCodeFromPath() + '/callback',
    responseType: 'token id_token',
    scope: 'openid profile email',
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getNaevoUserId = this.getNaevoUserId.bind(this);
  }

  getCountryCodeFromPath() {
    const arr = history.location.pathname.split('/');
    return arr[1];
  }

  login() {
    localStorage.setItem('redirectUrl', window.location.pathname);
    this.auth0.authorize();
  }

  async handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          return reject(err);
        }
        if (!authResult || !authResult.accessToken) {
          return reject(err);
        }
        // if (authResult && authResult.accessToken) {
        this.setSession(authResult).then(() => {
          return resolve();
        });
        // }
        //  else if (err) {
        //   // console.log(err);
        //   alert(`Error: ${err.error}. Check the console for further details.`);
        //   return reject(err);
        // }
      });
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  async setSession(authResult) {
    const profile = await this.getProfile(authResult.accessToken);
    const exist = await this.checkIfUserExists(profile.email, authResult.accessToken);
    return new Promise((resolve, reject) => {
      if (exist) {
        // console.log('match');
        // Set isLoggedIn flag in localStorage
        localStorage.setItem('isLoggedIn', 'true');
        // Set the time that the access token will expire at
        let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
        this.accessToken = authResult.accessToken;
        this.idToken = authResult.idToken;
        this.expiresAt = expiresAt;
        localStorage.setItem('userEmail', profile.email);
        localStorage.setItem('userName', profile.name);
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('expiresAt', expiresAt);
        // navigate to the home route
        // history.replace(localStorage.getItem('redirectUrl'));
        resolve();
      } else {
        reject();
        console.log('Du har ikke tilgang til denne siden');
      }
    });
  }

  async renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        this.logout();
        // console.log(err);
        alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;
    this.userProfile = null;

    // Remove isLoggedIn flag from localStorage
    // localStorage.removeItem('isLoggedIn');
    // localStorage.removeItem('expiresAt');
    // localStorage.removeItem('access_token');
    localStorage.clear();

    // navigate to the home route
    // history.replace('/');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    return new Date().getTime() < localStorage.getItem('expiresAt');
    // let expiresAt = localStorage.getItem('expiresAt');
    // if (new Date().getTime() < expiresAt) {
    //   // this.renewSession();
    //   return true;
    // }
    // return false;
  }

  getProfile(token) {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(token, (err, profile) => {
        if (profile) {
          this.userProfile = profile;
          resolve(profile);
        }
        reject(err);
      });
    });
  }

  // Check if logged in Auth0 user exists in naevo. If not, logout.
  checkIfUserExists(email, auth) {
    let token = process.env.REACT_APP_NAEVO_TOKEN_SE;
    let api_url = process.env.REACT_APP_NAEVO_API_SE;
    if (this.getCountryCodeFromPath() === 'no') {
      token = process.env.REACT_APP_NAEVO_TOKEN_NO;
      api_url = process.env.REACT_APP_NAEVO_API_NO;
    }
    if (this.getCountryCodeFromPath() === 'dk') {
      token = process.env.REACT_APP_NAEVO_TOKEN_DK;
      api_url = process.env.REACT_APP_NAEVO_API_DK;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(api_url + '/validateUser?email=' + email, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then(res => {
          if (res.data.email.localeCompare(email, undefined, { sensitivity: 'accent' }) === 0) {
            localStorage.setItem('naevoUser', res.data.id);
            resolve(res.data);
          } else
            reject('no user');
        });
    });
  }

  getNaevoUserId() {
    return localStorage.getItem("naevoUser");
  }
}

const a0 = new Auth();
export default a0;
