import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles, Grid } from '@material-ui/core';
import { Button } from '@progress/kendo-react-buttons';
import { scrollTop } from '../../../functions';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import PropTypes from 'prop-types';

import SummaryComponent from '../../Summary/SummaryComponent';
import Script from '../../Script';
import { naevoPostComment, naevoBookCase } from '../../../actions/naevo';

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});
class FourthStepComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showComment: false,
      commentString: '',
      visibleSlaDialog: false,
      slaAnswer: '',
    };
  }

  componentDidMount() {
    scrollTop();
  }

  bookCase = answer => {
    this.saveComment().then(() => {
      setTimeout(() => {
        this.saveBooking(answer).then((res) => {

          let response = res.kbs;

          if (!response)
            response = res.booked
          if (response) {
            setTimeout(() => {
              this.backToStart();
            }, 500);
          }
        }).catch(error => {
          this.props.toggleSlaDialog();
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Error!")
          }
        });
      }, 300);
    });
  };

  saveComment = async () => {
    let string = '';
    const step2 = this.props.steptwo;
    if (step2) {
      step2.filter(f => (f.atext || f.answer) && !this.shouldFeedbackQaBeHiddenInCaseCard(f)).forEach(el => {
        string = string + '\r' + el.qtext + ': ' + el.atext;
      });
      await this.props.naevoPostComment('Comment', string, localStorage.getItem('access_token'));
    }
  };

  shouldFeedbackQaBeHiddenInCaseCard = (answerData) => {
    let question = this.props.questions.find(f => f.question_id === answerData.question);
    let answer = answerData.answer ? this.props.answers.find(a => a.question_id === answerData.question && a.answer_id === answerData.answer) : null;

    if (question && question.dontShowInCaseCard) {
      return true;
    }

    return question && answer && answer.dontShowInCaseCard;
  };

  saveBooking = async answer => {
    const step3 = this.props.stepthree;
    if (step3) {
      step3.slaAnswer = answer;
      step3.answerData = '';
      return await this.props.naevoBookCase(step3, localStorage.getItem('access_token'));
    }
  };

  backToStart = () => {
    // const cc = this.countryCode();
    if (this.props.match.params.env) {
      window.location.href = '/' + this.props.match.params.env + '/' + this.props.match.params.countrycode + '/start';
    } else {
      window.location.href = '/' + this.props.match.params.countrycode + '/start';
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="row wrapper">
        <div className="col-xs-12 col-sm-6 offset-sm-3 col">
          <div className="card">
            <div className="card-block">
              <form className="k-form" onSubmit={this.handleSubmit}>
                <Paper className={classes.paper}>
                  <Script script={5} {...this.props} />
                </Paper>
              </form>
              <SummaryComponent countrycode={this.props.match.params.countrycode} formatPersonIdFromSeToNoStandart={this.props.formatPersonIdFromSeToNoStandart} formatPersonIdFromSeToDkStandart={this.props.formatPersonIdFromSeToDkStandart} />
              <Grid container spacing={8}>
                <Grid item md={12} style={{ textAlign: 'center' }}>

                  <Button primary={true} type="button" style={{ opacity: (this.props.allFinished() ? '1' : '0.30') }} onClick={this.props.checkSla}>
                    Save Booking
                  </Button>

                </Grid>
              </Grid>
              {this.state.showComment && (
                <Paper className={classes.paper} style={{ textAlign: 'center' }}>
                  <p>
                    <strong>COPY TO CASECARD CASE COMMENT (temporary solution): </strong>
                  </p>
                  <p>{this.state.commentString}</p>
                </Paper>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
FourthStepComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    naevoPostComment: (type, comment, auth) => dispatch(naevoPostComment(type, comment, auth)),
    naevoBookCase: (object, auth) => dispatch(naevoBookCase(object, auth)),
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(FourthStepComponent)
);
