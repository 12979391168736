import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});
function SideWindowHeaderComponent() {
  return (
    <div style={{ height: '150px' }}>
      <Grid container spacing={24}>
        <Grid item xs>
          <p>jdhgajhdgs</p>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(SideWindowHeaderComponent());
