import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
class CustomerInfoComponent extends Component {
  render() {
    const style = {
      height: '100%',
      background: 'white',
    };

    const { acase } = this.props;

    return (
      <div style={style}>
        <span>
          {acase.client.name && (
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <p>
                  <strong>
                    <Trans>Name</Trans>
                  </strong>
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>{acase.client.name}</p>
              </Grid>
            </Grid>
          )}
          {acase.client.personId && (
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <p>
                  <strong>
                    <Trans>Personal Identity Number</Trans>
                  </strong>
                </p>
              </Grid>
              <Grid item xs={6}>
                {this.props.countrycode === 'se' && (<p>{acase.client.personId + '-' + acase.client.ssn}</p>)}
                {this.props.countrycode === 'dk' && (<p>{this.props.formatPersonIdFromSeToDkStandart(acase.client.personId) + '-' + acase.client.ssn}</p>)}
                {this.props.countrycode === 'no' && (<p>{this.props.formatPersonIdFromSeToNoStandart(acase.client.personId) + '-' + acase.client.ssn}</p>)}
              </Grid>
            </Grid>
          )}
        </span>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomerInfoComponent)
);
