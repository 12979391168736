import React from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import Callback from './Auth/Callback';

// import Auth from './Auth/Auth';
import a0 from './Auth/AuthService';
import Start from './Start';

import App from './App';
import CountrySelect from './components/CountrySelect';

// const auth = new Auth();

// const handleAuthentication = (nextState, replace) => {
//   if (/access_token|id_token|error/.test(nextState.location.hash)) {
//     a0.handleAuthentication();
//   }
// };

function Routes(props) {
  return (
    <div className="App">
      {/* <Route exact path="/login" render={() => <Auth authenticated={authenticated} auth={props.auth} history={props.history} />} /> */}
      <Route
        path="/:countrycode/callback"
        render={props => {
          // handleAuthentication(props);
          return <Callback auth={a0} {...props} />;
        }}
      />
      <Route
        exact
        path="/:countrycode/:caseid/:step"
        render={props => {
          return <App history={props.history} auth={a0} {...props} />;
        }}
      />
      <Route exact path="/:countrycode/start" render={props => <Start history={props.history} {...props} />} />
      <Route exact path="/" render={props => <CountrySelect history={props.history} {...props} />} />
    </div>
  );
}

export default withRouter(Routes);
