import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withTranslation } from 'react-i18next';

import SummaryStepOneComponent from './SummaryStepOneComponent';
import SummaryStepTwoComponent from './SummaryStepTwoComponent';
import SummaryStepThreeComponent from './SummaryStepThreeComponent';

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

class SummaryComponent extends Component {
  constructor(props) {
    super(props);
    // this.props.glEventHub.on('something-happend', this.addFromEventHub);
    // this.props.glEventHub.on('formdata', this.addFromEventHub);

    this.state = {
      step1: [],
      step2: [],
    };
  }

  addFromEventHub = data => {
    this.setState(data);
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 offset-sm-3 col">
            <div className="card">
              <div className="card-block">
                <form className="k-form" onSubmit={this.handleSubmit}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={24}>
                      <Grid item sm={12} lg={6}>
                        <SummaryStepOneComponent stepone={this.props.stepone} countrycode={this.props.countrycode} formatPersonIdFromSeToNoStandart={this.props.formatPersonIdFromSeToNoStandart} formatPersonIdFromSeToDkStandart={this.props.formatPersonIdFromSeToDkStandart} />
                      </Grid>
                      <Grid item sm={12} lg={6}>
                        <SummaryStepTwoComponent steptwo={this.props.steptwo} />
                      </Grid>
                      <Grid item sm={12} lg={12}>
                        <SummaryStepThreeComponent stepthree={this.props.stepthree} />
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SummaryComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    stepone: state.stepone,
    steptwo: state.steptwo,
    stepthree: state.stepthree,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SummaryComponent)
  )
);
