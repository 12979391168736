import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import a0 from '../Auth/AuthService';

import loading from './inselogif.gif';
class Callback extends Component {
  // props.auth.handleAuthentication().then(() => {
  //   console.log('test');

  //   props.history.push('/booking');
  // });
  // async componentDidMount() {
  //   await auth0Client.handleAuthentication();
  //   this.props.history.replace('/1084154/0');
  // }
  async componentDidMount() {
    // console.log('callback mounted');
    await a0.handleAuthentication();
    // const authenticated = await this.props.auth.isAuthenticated();
    // if (authenticated) {
    //   console.log('AUTHENTICATED');
    this.props.history.replace(localStorage.getItem('redirectUrl'));
    // }
  }

  render() {
    const style = {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#FFFBFF',
      zIndex: 1000,
    };
    const loadingStyle = {
      position: 'absolute',
      top: '50%',
      bottom: '50%',
    };
    return (
      <div style={style}>
        <img src={loading} alt="loading" style={loadingStyle} />
      </div>
    );
  }
}

export default withRouter(Callback);
