// import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers/index.js';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';

const config = {
  // TOGGLE_TODO will not be triggered in other tabs
  blacklist: [],
};
const composeEnhancers = composeWithDevTools({
  trace: process.env.NODE_ENV === 'development' ? true : false,
  traceLimit: 25,
});
const middlewares = [thunk, createStateSyncMiddleware(config)];

export const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)));

initStateWithPrevTab(store);
