import * as Constants from '../Constants';

const scrollTop = () => {
  var myDiv = document.getElementById('mainWrapper');
  myDiv.scrollTop = 0;
};

const getNaevoApiUrl = () => {
  if (countryCode() === 'se') {
    return process.env.REACT_APP_NAEVO_API_SE;
  } else if (countryCode() === 'no') {
    return process.env.REACT_APP_NAEVO_API_NO;
  } else {
    return process.env.REACT_APP_NAEVO_API_DK;
  }
}

const getNaevoApiToken = () => {
  if (countryCode() === 'se') {
    return process.env.REACT_APP_NAEVO_TOKEN_SE;
  } else if (countryCode() === 'no') {
    return process.env.REACT_APP_NAEVO_TOKEN_NO;
  } else {
    return process.env.REACT_APP_NAEVO_TOKEN_DK;
  }
}

const countryCode = () => {
  const arr = window.location.pathname.split('/');
  if (arr[1] !== 'no' && arr[1] !== 'se' && arr[1] !== 'dk') {
    return arr[2];
  }
  return arr[1];
};

const getStateColor = (state) => {
  switch (state) {
    case Constants.NAEVO_CASE_STATE.BOOKED:
      return "#4caf50";
    case Constants.NAEVO_CASE_STATE.PRELIMINAR:
      return "#00bcd4";
    case Constants.NAEVO_CASE_STATE.SELFSERVICE:
      return "#ff9800";
    default:
      return "#000";
  }
}

export { scrollTop, getNaevoApiUrl, getNaevoApiToken, getStateColor };
