export function geopostalcode(state = [], action) {
  switch (action.type) {
    case 'GEO_CHECK_POSTALCODE_SUCCESS':
      return Object.assign({}, action.geopostalcode);
    default:
      return state;
  }
}
export function geoarea(state = [], action) {
  switch (action.type) {
    case 'GEO_CHECK_AREA_SUCCESS':
      return action.geoarea;
    default:
      return state;
  }
}
