import axios from 'axios';
import { getAnsweredQuestionsByCase } from './other';
import * as CONSTANTS from '../Constants';
import { getNaevoApiUrl, getNaevoApiToken } from '../functions';

const token = getNaevoApiToken();
const api_url = getNaevoApiUrl();

// ACTIONS
export function questionsFetchDataSuccess(questions) {
  return {
    type: 'QUESTIONS_FETCH_DATA_SUCCESS',
    questions,
  };
}

export function informationQuestionsFetchDataSuccess(questions) {
  return {
    type: 'INFORMATION_QUESTIONS_FETCH_DATA_SUCCESS',
    questions,
  };
}

export function mersalgQuestionsFetchDataSuccess(questions) {
  return {
    type: 'MERSALG_QUESTIONS_FETCH_DATA_SUCCESS',
    questions,
  };
}

export function answersFetchDataSuccess(answers) {
  return {
    type: 'ANSWERS_FETCH_DATA_SUCCESS',
    answers,
  };
}

export function scriptsFetchDataSuccess(scripts) {
  return {
    type: 'SCRIPTS_FETCH_DATA_SUCCESS',
    scripts,
  };
}

// ACTION CREATORS
export function questionsFetchData(source, ct, auth) {
  return (dispatch, getState) => {
    const tax = getState().tax.length === 0;
    axios({
      method: 'GET',
      url: api_url + '/bmQuestions/' + source + "/" + ct + "?tax=" + tax,
        headers: {
          Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        const questions = res.data;
        //Change to get these from API
        dispatch(getAnsweredQuestionsByCase(questions, auth));
        return questions;
      })
      .then(questions => dispatch(questionsFetchDataSuccess(questions)));
  };
}
export function informationQuestionsFetchData(source, ct, auth) {
  // console.log(source);
  // console.log(ct);
  return dispatch => {
    axios({
      method: 'GET',
      url: api_url + '/bmQuestions/' + source + "/" + ct + "?moduleType=" + CONSTANTS.FEEDBACK_QUESTION_MODULE_TYPES.INFORMATION,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.data;
      })
      .then(questions => dispatch(informationQuestionsFetchDataSuccess(questions)));
  };
}

export function mersalgQuestionsFetchData(source, ct, auth) {
  return dispatch => {
    axios({
      method: 'GET',
      url: api_url + '/bmQuestions/' + source + "/" + ct + "?moduleType=" + CONSTANTS.FEEDBACK_QUESTION_MODULE_TYPES.MERSALG,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        return res.data;
      })
      .then(questions => dispatch(mersalgQuestionsFetchDataSuccess(questions)));
  };
}
export function answersFetchData(source, ct, auth) {
  return (dispatch, getState) => {
    const tax = getState().tax.length === 0;
    axios({
      method: 'GET',
      url: api_url + '/bmAnswers/' + source + '/' + ct + '/' + tax,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        const result = [];
        res.data.forEach(el => {
          if (el.text) {
            result.push(el);
          }
        });
        const answers = result;
        return answers;
      })
      .then(answers => dispatch(answersFetchDataSuccess(answers)));
  };
}

export function scriptsFetchData(auth) {
  return dispatch => {
    axios({
      method: 'GET',
      url: api_url + '/bmScripts',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        const scripts = res.data;
        return scripts;
      })
      .then(scripts => dispatch(scriptsFetchDataSuccess(scripts)));
  };
}
