import React from 'react';
// import { Grid, GridColumn as Column, GridRow as Row } from '@progress/kendo-react-grid';
import Paper from '@material-ui/core/Paper';
import { withStyles, TableFooter } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import PropTypes from 'prop-types';

import './ArticlesComponent.css';
// import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
    overflow: 'scroll',
  },
  table: {
    minWidth: 700,
  },
});

function ArticlesComponent(props) {
  const { t } = useTranslation();
  const { classes } = props;
  const mva = 1.25;
  const calculateSum = () => {
    let sum = 0;
    props.articles.forEach(a => {
      if (a.state === 1) {
        sum += +a.quantity * (+a.price_C * mva);
      }
    });
    return sum;
  };
  return (
    <div>
      <form className="k-form">
        <Paper className={classes.paper}>
          {/* <Grid data={props.articles}>
        <Column field="description" title="Artikkel" width="600px" />
        <Column field="quantity" title="Antall" width="100px" />
        <Column field="price_C" title="Pris" width="100px" />
        <Column field="Sum" title="Sum" width="100px" cell={props => <p>{props.dataItem.quantity * +props.dataItem.price_C}</p>} />
        <Row title="SUM" type="groupFooter" rowRender={props => <span>{props.dataItem.quantity * +props.dataItem.price_C}</span>} />
      </Grid> */}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{t('Article')}</TableCell>
                <TableCell align="right">{t('Quantity')}</TableCell>
                <TableCell align="right">{t('Price')}</TableCell>
                <TableCell align="right">{t('Sum')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.articles
                .filter(article => article.state === 1)
                .map(row => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">{row.price_C * mva}</TableCell>
                    <TableCell align="right">{row.quantity * (row.price_C * mva)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('Sum')}
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right">
                  <strong>{calculateSum()}</strong>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </form>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ArticlesComponent)
);
