import React from 'react';

export default function SelfServiceInfo(props) {
    console.log(props);

    return (
        props.case.selfServiceInfo ? (
            <fieldset>
                <legend>
                    SelfService
                </legend>
                <br />
                <span className="taleManus">
                    Self Service sent: {props.case.selfServiceInfo.sentDate}
                </span>
                <br />
                <span className="taleManus">
                    Self Service opened by customer: {props.case.selfServiceInfo.openedByCustomer ? "Yes" : "No"}
                </span>
                <br />
                <span className="taleManus">
                    Address changed by customer: {props.case.selfServiceInfo.addressChanged ? "Yes" : "No"}
                </span>
                <br />
                <span className="taleManus">
                    Status change forced by QA: {props.case.selfServiceInfo.statusChangedForcedByQA ? "Yes" : "No"}
                </span>
                <br />
                <span className="taleManus">
                    Customer accepted time suggestion: {props.case.selfServiceInfo.customerAcceptedTimeSuggestion}
                </span>
                <br />
                <span className="taleManus">
                    Self service completed: {props.case.selfServiceInfo.ssCompleted ? "Yes" : "No"}
                </span>
            </fieldset>
        ) : ("Self service was not initiated for this case")
    );
}