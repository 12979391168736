import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';

import './ButtonComponent.css';
import { Grid } from '@material-ui/core';

export default function ButtonComponent(props) {
  const { t } = useTranslation();

  return (
    <div className="btnWrapper">
      <Grid container spacing={8}>
        <Grid item md={4} style={{ textAlign: 'left' }}>
          <Button secondary="true" onClick={props.cancel}>
            {t('Cancel')}
          </Button>
        </Grid>
        <Grid item md={4} style={{ textAlign: 'center' }}>
          {/* <Button primary={true}>{t('Save')}</Button> */}
          {props.step === 3 && (
            <Button primary={true} type="button" style={{ opacity: (props.allFinished() ? '1' : '0.30') }} onClick={props.checkSla}>
              Save Booking
            </Button>
          )}
        </Grid>
        <Grid item md={4} style={{ textAlign: 'right' }}>
          {props.step !== 0 && (
            <Button secondary="true" onClick={props.prev} disabled={props.step === 0}>
              {t('Back')}
            </Button>
          )}
          {props.step !== 3 && (
            <Button primary={true} onClick={props.next} disabled={props.step === 3 || props.completedStep !== props.step}>
              {t('Next')}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
