import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
class ContactInfoComponent extends Component {
  render() {
    const style = {
      height: '100%',
      background: 'white',
    };

    const { acase } = this.props;
    return (
      <div style={style}>
          <span>
          {acase.client && (
            <span>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Installation Address</Trans>
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{acase.client.installAddressStreet}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Installation Postal Zip</Trans>
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{acase.client.installAddressZip}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Installation Postal City</Trans>
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{acase.client.installAddressCity}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Invoice Address</Trans>
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{acase.client.invoiceAddressStreet}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Invoice Postal Zip</Trans>
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{acase.client.invoiceAddressZip}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Invoice Postal City</Trans>
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{acase.client.invoiceAddressCity}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Email</Trans>
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{acase.client.email}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Phone Number</Trans> (<Trans>Primary</Trans>)
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{acase.client.phone1}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Phone Number</Trans> (<Trans>Secondary</Trans>)
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{acase.client.phone2}</p>
                  </Grid>
                </Grid>
              </span>
            )}
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContactInfoComponent)
);
