import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { DateRange } from 'react-date-range';
import * as moment from 'moment';
import * as rdrLocales from 'react-date-range/dist/locale';
import { scrollTop } from '../../../functions';
import { Button } from '@progress/kendo-react-buttons';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import Script from '../../Script';
import { naevoGetOverviewByPartner, naevoGetOverviewByPartnerAndInstaller } from '../../../actions/naevo';
import PartnersListComponent from './PartnersListComponent';
import SuggestedPartnerListComponent from './SuggestedPartnerListComponent';
import PartnerOverviewComponent from './PartnerOverviewComponent';
import { setStepThree } from '../../../actions/other';

import AvailabilityGridComponent from './AvailabilityGridComponent';
import InstallersListComponent from './InstallersListComponent';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});
class ThirdStepComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(Date.now() + 12096e5),
        key: 'selection',
      },
      startDate: moment(new Date()),
      endDate: moment(new Date(Date.now() + 12096e5)),
      dates: null,
      selectedPartnerId: null
    };
  }

  componentDidMount() {
    scrollTop();
    if (this.props.overview.length === 0 && this.props.geopostalcode.details && this.props.geopostalcode.details.length > 0) {
      if (this.props.match.params.countrycode === 'se' || this.props.match.params.countrycode === 'dk') {
        if (this.props.geopostalcode.details[0].installers) {
          const firstInArray = this.props.geopostalcode.details[0].installers[0];
          if (firstInArray)
            this.getInstallerOverview(firstInArray.partnerId, firstInArray.id);
        }
      } else if (this.props.match.params.countrycode === 'no') {
        this.getOverview(this.props.geopostalcode.details[0].partner.id);
      }
    }
    setTimeout(() => {
      this.props.container.on('show', () => {
        console.log('OPENOPENOPEN');
      });
    }, 100);
    // this.props.eventhub.emit('show', () => {
    //   this.props.eventhub.on('show', () => {
    //     console.log('SHOW!');
    //     this.props.container.layoutManager.root.contentItems[0].scrollTo(0, 0);
    //   });
    //   console.log('EVENT SHOW');
    // });
    // this.props.container.scrollTop(0, 0);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.overview.length === 0 && this.props.geopostalcode.details && this.props.geopostalcode.details.length > 0) {
      // if (this.props.geopostalcode.details[0].partner) this.getOverview(this.props.geopostalcode.details[0].partner.id);
      // if (this.props.geopostalcode.details[0].partner) {
      //   this.props.geopostalcode.details[0].partner.find(p => this.getOverview(p.id));
      // }
      if (this.props.match.params.countrycode === 'se' || this.props.match.params.countrycode === 'dk') {
        if (this.props.geopostalcode.details[0].installers) {
          const firstInArray = this.props.geopostalcode.details[0].installers[0];
          if (firstInArray) {
            this.getInstallerOverview(firstInArray.partnerId, firstInArray.id);
          }
        }
      } else if (this.props.match.params.countrycode === 'no') {
        this.getOverview(this.props.geopostalcode.details[0].partner.id);
      }
    }
  }

  handleSelect = ranges => {
    const range = this.state.selectionRange;
    range.startDate = ranges.selection.startDate;
    range.endDate = ranges.selection.endDate;

    this.setState({
      selectionRange: range,
    }, () => this.getOverview(this.state.selectedPartnerId));
  };

  getOverview = partnerid => {
    if (partnerid) {
      this.setState({
        selectedPartnerId: partnerid
      });

      this.props.getPartnerOverview(
        partnerid,
        this.state.selectionRange.startDate,
        this.state.selectionRange.endDate,
        localStorage.getItem('access_token')
      );
    }
  };

  getInstallerOverview = async (partnerid, employeeid) => {
    await this.props.getInstallerOverview(
      partnerid,
      employeeid,
      this.state.selectionRange.startDate,
      this.state.selectionRange.endDate,
      localStorage.getItem('access_token')
    );
  };

  step3Finished = e => {
    console.log(e);
    this.props.setStepThree(e);
    this.props.stepComplete(2);
    this.props.goToNext();
  };

  render() {
    const { classes } = this.props;
    const cc = this.props.match.params.countrycode;
    // const { days } = this.props.overview[0].employees.length > 0 ? this.props.overview[0].employees[0] : 0;

    // const refs = days.reduce((acc, value) => {
    //   acc[value.id] = createRef();
    //   return acc;
    // }, {});

    return (
      <div className={classes.root}>
        <div className="col-xs-12 col-sm-6 offset-sm-3 col">
          <div className="card">
            <div className="card-block">
              <form className="k-form" onSubmit={this.handleSubmit}>
                <Paper className={classes.paper}>
                  <Script script={4} {...this.props} />
                </Paper>
                <br />
                <Grid container spacing={8}>
                  {/* <Grid item md={12} style={{ textAlign: 'center' }}>
                  </Grid> */}
                  <Grid item md={12} lg={3}>
                    <div style={{ position: '-webkit-sticky', position: 'sticky', top: '15px', overflowY: 'scroll', maxHeight: '850px' }}>
                      {this.props.acase.installDate && this.props.acase.installDate !== '' && this.props.acase.installTime && this.props.acase.installTime !== '' && (
                        <div>
                          <Grid item md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Paper className={classes.paper}>
                              <div>
                                <Trans>InstallDate</Trans> {moment(this.props.acase.installDate).lang(cc === "se" ? "sv" : "nb").format('dddd YYYY-MM-DD')} Slot: {this.props.acase.installTime}
                              </div>
                            </Paper>
                          </Grid>
                          <br />
                        </div>
                      )}

                      <Grid item md={12} lg={12} style={{ textAlign: 'center' }}>
                        {cc === 'se' && (
                          <DateRange
                            onChange={this.handleSelect}
                            moveRangeOnFirstSelection={false}
                            ranges={[this.state.selectionRange]}
                            className={'PreviewArea'}
                            minDate={new Date()}
                            locale={rdrLocales.sv}
                          />
                        )}
                        {cc === 'no' && (
                          <DateRange
                            onChange={this.handleSelect}
                            moveRangeOnFirstSelection={false}
                            ranges={[this.state.selectionRange]}
                            className={'PreviewArea'}
                            minDate={new Date()}
                            locale={rdrLocales.nb}
                          />
                        )}
                        {cc === 'dk' && (
                          <DateRange
                            onChange={this.handleSelect}
                            moveRangeOnFirstSelection={false}
                            ranges={[this.state.selectionRange]}
                            className={'PreviewArea'}
                            minDate={new Date()}
                            locale={rdrLocales.nb}
                          />
                        )}
                      </Grid>
                      <Grid item md={12} lg={12} style={{ textAlign: 'center' }}>
                        {this.state.selectedPartnerId && (
                          <Button
                            style={{ marginTop: "20px" }}
                            primary={true} type={"button"}
                            onClick={() => this.getOverview(this.state.selectedPartnerId)}
                          >
                            <Trans>Update</Trans>
                          </Button>
                        )}
                      </Grid>
                      <br />
                      {this.props.geopostalcode.details && this.props.geopostalcode.details.length > 0 && (
                        <span>
                          <Paper className={classes.paper}>
                            {cc === 'no' && <SuggestedPartnerListComponent getOverview={this.getOverview} partners={this.props.partners} cc={cc} />}
                            {cc === 'se' && (
                              <SuggestedPartnerListComponent getOverview={this.getInstallerOverview} partners={this.props.partners} cc={cc} />
                            )}
                            {cc === 'dk' && (
                              <SuggestedPartnerListComponent getOverview={this.getInstallerOverview} partners={this.props.partners} cc={cc} />
                            )}
                          </Paper>

                          <br />
                        </span>
                      )}
                      <Grid item md={12} lg={12}>
                        <Paper className={classes.paper}>
                          {/* if NO then show partners, SE show installers
                        {cc === 'no' && <PartnersListComponent getOverview={this.getOverview} />}
                        {cc === 'se' && <InstallersListComponent getOverview={this.getInstallerOverview} />} */}
                          <PartnersListComponent getOverview={this.getOverview} />
                        </Paper>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item md={12} lg={9}>
                    {this.props.overview.map((o, i) => (
                      <span key={i}>
                        <PartnerOverviewComponent partner={o} finished={this.step3Finished} {...this.props} />
                      </span>
                    ))}
                    <br />
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ThirdStepComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPartnerOverview: (partnerid, fromdate, todate, auth) => dispatch(naevoGetOverviewByPartner(partnerid, fromdate, todate, auth)),
    getInstallerOverview: (partnerid, installerid, fromdate, todate, auth) =>
      dispatch(naevoGetOverviewByPartnerAndInstaller(partnerid, installerid, fromdate, todate, auth)),
    setStepThree: object => dispatch(setStepThree(object)),
  };
};

export default withTranslation()(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ThirdStepComponent)
  )
);
