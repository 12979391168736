import React from 'react';
import { Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import * as Moment from 'moment';

import Script from '../../Script';
import SelfServiceInfo from '../../SelfSeviceInfo';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
});

const section = {
  height: "100%",
};
function FirstStepForm(props) {
  const { classes } = props;
  const { t } = useTranslation();
  if (!props.client) {
    return <div>Loading...</div>;
  }
  return (
    <div className={classes.root}>
      <form className="k-form" id="firstStepForm" onSubmit={props.handleSubmit}>
        <Grid container spacing={2} direction="row">
          {props.case.selfServiceInfo ? (
            <React.Fragment>
              <Grid item xs={7} style={{ marginBottom: "60px" }}>
                <Paper style={{ height: "100%" }} className={classes.paper}>
                  <Script script={1} {...props} />
                </Paper>
                <br />
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "60px" }}>
                <Paper style={{ height: "100%" }} className={classes.paper}>
                  <SelfServiceInfo {...props} />
                </Paper>
                <br />
              </Grid>

            </React.Fragment>) :
            (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Script script={1} {...props} />
                </Paper>
                <br />
              </Grid>)
          }

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <fieldset>
                <legend>
                  <Trans>Quality Assurance</Trans>
                </legend>
                <br />

                <Grid item xs={12}>
                  <label className="k-form-field">
                    <span className="formLabels">
                      <Trans>Name</Trans>:
                    </span>
                    {props.client.map((c, i) => (
                      <span key={i}>{c.name && <Input className="labelInput" name="name" defaultValue={c.name} />}
                        <Input name="clientId" defaultValue={c.id} type={"hidden"} />
                      </span>
                    ))}
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label className="k-form-field">
                    <span className="formLabels">
                      <Trans>Installation Address</Trans>:
                    </span>
                    <div>
                      {props.client.map((c, i) => (
                        <span key={i}>
                          <Grid container spacing={8}>
                            {c.installAddressName && (
                              <Grid item xs>
                                <Input className="labelInput" name="installAddressName" label={t('Name')} defaultValue={c.installAddressName} />
                              </Grid>
                            )}
                            {c.installAddressStreet && (
                              <Grid item xs>
                                <Input
                                  className="labelInput"
                                  name="installAddressStreet"
                                  label={t('Address')}
                                  defaultValue={c.installAddressStreet}
                                />
                              </Grid>
                            )}
                            {c.installAddressZip && (
                              <Grid item xs>
                                <Input className="labelInput" name="installAddressZip" label={t('Postal Zip')} defaultValue={c.installAddressZip} />
                              </Grid>
                            )}
                            {c.installAddressCity && (
                              <Grid item xs>
                                <Input
                                  className="labelInput"
                                  name="installAddressCity"
                                  label={t('Postal City')}
                                  defaultValue={c.installAddressCity}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </span>
                      ))}
                    </div>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label className="k-form-field">
                    <span className="formLabels">
                      <Trans>Invoice Address</Trans>:
                    </span>
                    <div style={{ display: 'inline' }}>
                      {props.client.map((c, i) => (
                        <span key={i}>
                          <Grid container spacing={8}>
                            {c.invoiceAddressName && (
                              <Grid item xs>
                                <Input className="labelInput" name="invoiceAddressName" label={t('Name')} defaultValue={c.invoiceAddressName} />
                              </Grid>
                            )}
                            {c.invoiceAddressStreet && (
                              <Grid item xs>
                                <Input
                                  className="labelInput"
                                  name="invoiceAddressStreet"
                                  label={t('Address')}
                                  defaultValue={c.invoiceAddressStreet}
                                />
                              </Grid>
                            )}
                            {c.invoiceAddressZip && (
                              <Grid item xs>
                                <Input className="labelInput" name="invoiceAddressZip" label={t('Postal Zip')} defaultValue={c.invoiceAddressZip} />
                              </Grid>
                            )}
                            {c.invoiceAddressCity && (
                              <Grid item xs>
                                <Input
                                  className="labelInput"
                                  name="invoiceAddressCity"
                                  label={t('Postal City')}
                                  defaultValue={c.invoiceAddressCity}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </span>
                      ))}
                    </div>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label className="k-form-field">
                    <span className="formLabels">
                      <Trans>Email</Trans>:
                    </span>
                    {props.client.map((c, i) => (
                      <span key={i}>
                        {c.email && <Input className="labelInput" name="email" defaultValue={c.email} />}
                        {c.email === '' && <Input className="labelInput" name="email" defaultValue={c.email} />}
                      </span>
                    ))}
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label className="k-form-field">
                    <span className="formLabels">
                      <Trans>Phone Number</Trans> (<Trans>Primary</Trans>):
                    </span>
                    {props.client.map(c => (
                      <span key={c.phone1}>
                        <span>
                          {c.phone1 && <Input className="labelInput" name="phone1" defaultValue={c.phone1} onBlur={props.editedPhone} />}
                          {c.phone1 === '' && <Input className="labelInput" name="phone1" defaultValue={c.phone1} onBlur={props.editedPhone} />}
                        </span>
                      </span>
                    ))}
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label className="k-form-field">
                    <span>
                      <Grid container spacing={24}>
                        <Grid item xs>
                          <span className="formLabels">
                            <Trans>Phone Number</Trans> (<Trans>Secondary</Trans>):
                          </span>
                        </Grid>
                        <Grid item xs={8}>
                          <button className="buttonLink" type="button" onClick={props.changePhone}>
                            <Trans>Make primary</Trans>
                          </button>
                        </Grid>
                      </Grid>
                    </span>
                    {props.client.map(c => (
                      <span key={c.phone2}>
                        <span>
                          {c.phone2 && <Input className="labelInput" name="phone2" defaultValue={c.phone2} onBlur={props.editedPhone} />}
                          {c.phone2 === '' && <Input className="labelInput" name="phone2" defaultValue={c.phone2} onBlur={props.editedPhone} />}
                        </span>
                      </span>
                    ))}
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label className="k-form-field">
                    <span className="formLabels">
                      <Trans>Personal Identity Number</Trans>:
                    </span>
                    {props.client.map((c, i) => (
                      <span key={i}>
                        <Grid container spacing={8}>
                          {props.match.params.countrycode === 'se' && (
                            <Grid item xs={3}>
                              <Input
                                className="labelInput"
                                onChange={props.validatePersonId}
                                valid={props.validpersonIdNumber}
                                required={true}
                                name="personId"
                                minLength="8"
                                maxLength="8"
                                placeholder="yyyyMMdd"
                                validationMessage="Wrong Format"
                                defaultValue={c.personId} />
                            </Grid>
                          )}
                          {props.match.params.countrycode === 'se' && c.invoiceAddressStreet && (
                            <Grid item xs={3}>
                              <Input
                                className="labelInput ssn"
                                required={true}
                                name="ssn"
                                minLength="4"
                                maxLength="4"
                                placeholder="0000"
                                validationMessage="Wrong Format"
                                defaultValue={c.ssn} />
                            </Grid>
                          )}
                          {props.match.params.countrycode === 'no' && (
                            <Grid item xs={3}>
                              <Input
                                className="labelInput"
                                required={true}
                                onChange={props.validatePersonId}
                                valid={props.validpersonIdNumber}
                                name="personId"
                                minLength="8"
                                maxLength="8"
                                placeholder="ddMMyyyy"
                                validationMessage="Wrong Format"
                                defaultValue={props.formatPersonIdFromSeToNoStandart(c.personId)} />
                            </Grid>
                          )}
                          {props.match.params.countrycode === 'no' && c.invoiceAddressStreet && (
                            <Grid item xs={3}>
                              <Input
                                className="labelInput ssn"
                                required={true}
                                name="ssn"
                                minLength="5"
                                maxLength="5"
                                placeholder="00000"
                                validationMessage="Wrong Format"
                                defaultValue={c.ssn} />
                            </Grid>
                          )}
                          {props.match.params.countrycode === 'dk' && (
                            <Grid item xs={3}>
                              <Input
                                className="labelInput"
                                onChange={props.validatePersonId}
                                valid={props.validpersonIdNumber}
                                required={true}
                                name="personId"
                                minLength="8"
                                maxLength="8"
                                placeholder="ddMMyyyy"
                                validationMessage="Wrong Format"
                                defaultValue={props.formatPersonIdFromSeToDkStandart(c.personId)} />
                            </Grid>
                          )}
                          {props.match.params.countrycode === 'dk' && c.invoiceAddressStreet && (
                            <Grid item xs={3}>
                              <Input
                                className="labelInput ssn"
                                required={true}
                                name="ssn"
                                minLength="4"
                                maxLength="4"
                                placeholder="0000"
                                validationMessage="Wrong Format"
                                defaultValue={c.ssn} />
                            </Grid>
                          )}
                        </Grid>
                      </span>
                    ))}
                  </label>
                </Grid>
              </fieldset>
              <Button primary={true} type="submit">
                <Trans>Save</Trans>
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}


FirstStepForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withTranslation()(withStyles(styles)(FirstStepForm));
