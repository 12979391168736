import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import * as Moment from 'moment';
import { connect } from 'react-redux';
import { naevoPostComment } from '../../../actions/naevo';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    marginTop: 20,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  textBox: {
    width: '100%',
    height: 200,
  }
});

class InternalComComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intComment: ''
    };
  }

  splitComment = comment => {
    const arr = comment.split('\n');
    // arr.shift(0);
    return arr;
  };

  saveComment = () => {
    const comment = this.state.intComment;
    if (comment) {
      // this.props.acase.internalComment = '\n' + Moment().format("DD/MM hh:mm") + " " + localStorage.getItem('userName') + ": " + comment + '\n' + this.props.acase.internalComment;
      this.setState({ intComment: '' });
      this.sendComment(comment);
    };
  }

  sendComment = async (comment) => {
    return await this.props.naevoPostComment('InternalComment', comment, localStorage.getItem('access_token'));
  }

  handleChange = (event) => {
    this.setState({ intComment: event.target.value });
  };

  render() {
    console.log(this.props)
    const { classes } = this.props;
    return (
      <div>
        <span>
          <form className="k-form">
            <span>
              <label>Comment: </label>
              <input type="textarea"
                className={classes.textBox}
                name="textValue"
                value={this.state.intComment}
                onChange={this.handleChange}
              />
              <br />
              <button type="button" className="k-button" onClick={this.saveComment}>Send</button>
              <Paper className={classes.paper}>{this.splitComment(this.props.internalComment).map((com, ii) => (
                <span key={ii}>
                  {com.length === 11 && com.indexOf('-') > -1 && com.indexOf(':') > -1 && <p>{com}</p>}
                  {com.length !== 11 && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: com,
                      }}
                    />
                  )}
                </span>
              ))}
              </Paper>
            </span>
          </form>
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    naevoPostComment: (type, comment, auth) => dispatch(naevoPostComment(type, comment, auth)),
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InternalComComponent)
);
