import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import FirstStepComponent from './FirstStep/FirstStepComponent';
import SecondStepComponent from './SecondStep/SecondStepComponent';
// import ThirdStepComponent from './ThirdStep/ThirdStepComponent';
import ThirdStepComponent from './ThirdStep/ThirdStepComponent';
import FourthStepComponent from './FourthStep/FourthStepComponent';

import './MainWindowComponent.css';
import ButtonComponent from './Navigation/ButtonComponent';
import Header from '../Header';
import { stepperChange } from '../../actions/other';
import CancelDialog from '../CancelDialog';
import SlaDialog from '../../components/MainWindow/FourthStep/SlaDialog';

import { naevoSetCaseResting, naevoSetCaseCancelled, naevoSetCaseStateAsPhoneCall } from '../../actions/naevo';
import { withStyles } from '@material-ui/core';

import * as Moment from 'moment';

const styles = theme => ({
  // Load app bar information from the theme
  toolbar: theme.mixins.toolbar,
});

class MainWindowComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: parseInt(this.props.match.params.step),
      visibleCancelDialog: false,
      visibleSlaDialog: false,
      slaAnswer: '',
      cancelReason: null,
      cancelled: false,
      completedSteps: [],
      cancelDate: new Date(),
      restComment: '',
      width: this.props.glContainer.width,
    };
    this.child = React.createRef();
  }

  componentDidUpdate() {
    if (isNaN(this.state.activeStep)) {
      this.setState({
        activeStep: parseInt(this.props.match.params.step),
      });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.getDimensions.bind(this));
    this.getDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getDimensions.bind(this));
  }

  getDimensions = () => {
    let width = this.props.glContainer.width;
    this.props.glContainer.on('resize', () => {
      console.log('resize');
      width = this.props.glContainer.width;
      this.setState({
        width: width,
      });
    });
  };

  nextStep = () => {
    if (this.state.activeStep < 3) {
      if (this.props.match.params.env) {
        this.props.history.push(
          '/' +
          this.props.match.params.env +
          '/' +
          this.props.match.params.countrycode +
          '/' +
          this.props.match.params.caseid +
          '/' +
          (this.state.activeStep + 1)
        );
      } else {
        this.props.history.push('/' + this.props.match.params.countrycode + '/' + this.props.match.params.caseid + '/' + (this.state.activeStep + 1));
      }
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };
  prevStep = () => {
    // console.log(this.state.activeStep);
    if (this.state.activeStep > 0) {
      if (this.props.match.params.env) {
        this.props.history.push(
          '/' +
          this.props.match.params.env +
          '/' +
          this.props.match.params.countrycode +
          '/' +
          this.props.match.params.caseid +
          '/' +
          (this.state.activeStep - 1)
        );
      } else {
        this.props.history.push('/' + this.props.match.params.countrycode + '/' + this.props.match.params.caseid + '/' + (this.state.activeStep - 1));
      }
      this.setState({
        activeStep: this.state.activeStep - 1,
      });
    }
  };

  goToStep = step => {
    this.props.history.push('/' + this.props.match.params.countrycode + '/' + this.props.match.params.caseid + '/' + step);
    this.setState({
      activeStep: step,
    });
  };

  stepComplete = step => {
    // console.log('StepComplete: ', step);
    this.setState(
      {
        completedSteps: [...this.state.completedSteps, step],
      },
      () => {
        this.stepIsCompleted();
      }
    );
  };

  stepIsCompleted = () => {
    this.state.completedSteps.find(step => {
      if (step === this.state.activeStep) {
        // console.log(true);
        return true;
      }
      // console.log(false);
      return false;
    });
  };

  toggleSlaDialog = () => {
    this.setState({
      visibleSlaDialog: !this.state.visibleSlaDialog,
    });
  };

  checkSla = () => {
    if (this.checkAllFinished()) {
      const step3 = this.props.stepthree;
      const acase = this.props.acase;
      if (new Date(step3.date).getTime() > new Date(acase.slaDeadline).getTime() && !step3.kbs && acase.feedbackQuestions.filter(qq => qq.specialTrigger === 1).length > 0) {
        this.toggleSlaDialog();
      } else this.bookCase();
    } else {
      let error = "One of steps is not finished!";
      if (Object.keys(this.props.stepone).length <= 0) {
        error = "Step 1 is not finished!";
      } else if (Object.keys(this.props.steptwo).length <= 0) {
        error = "Step 2 is not finished!";
      } else if (Object.keys(this.props.stepthree).length <= 0) {
        error = "Step 3 is not finished!";
      };
      toast.error(error);
    }
  };

  setSlaAnswer = e => {
    console.log(e.target.value);
    this.setState({
      slaAnswer: e.target.value,
    });
  };

  bookCase = () => {
    console.log(this.child);
    this.child.bookCase(this.state.slaAnswer);
  };

  getStepContent = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          <FirstStepComponent
            eventhub={this.props.glEventHub}
            container={this.props.glContainer}
            auth={this.props.auth}
            formatPersonIdFromSeToNoStandart={this.formatPersonIdFromSeToNoStandart}
            formatPersonIdFromNoToSeStandart={this.formatPersonIdFromNoToSeStandart}
            formatPersonIdFromDkToSeStandart={this.formatPersonIdFromDkToSeStandart}
            formatPersonIdFromSeToDkStandart={this.formatPersonIdFromSeToDkStandart}
            isValidPersonId={this.isValidPersonId}
            stepComplete={this.stepComplete}
            match={this.props.match}
            goToNext={this.nextStep}
            allFinished={this.checkAllFinished}
          />
        );
      case 1:
        return (
          <SecondStepComponent
            eventhub={this.props.glEventHub}
            container={this.props.glContainer}
            auth={this.props.auth}
            stepComplete={this.stepComplete}
            match={this.props.match}
            goToNext={this.nextStep}
            allFinished={this.checkAllFinished}
          />
        );
      case 2:
        return (
          <ThirdStepComponent
            eventhub={this.props.glEventHub}
            container={this.props.glContainer}
            auth={this.props.auth}
            stepComplete={this.stepComplete}
            match={this.props.match}
            goToNext={this.nextStep}
            allFinished={this.checkAllFinished}
          />
        );
      case 3:
        return (
          <FourthStepComponent
            eventhub={this.props.glEventHub}
            container={this.props.glContainer}
            auth={this.props.auth}
            formatPersonIdFromSeToNoStandart={this.formatPersonIdFromSeToNoStandart}
            formatPersonIdFromSeToDkStandart={this.formatPersonIdFromSeToDkStandart}
            stepComplete={this.stepComplete}
            match={this.props.match}
            checkSla={this.checkSla}
            toggleSlaDialog={this.toggleSlaDialog}
            innerRef={node => (this.child = node)}
            allFinished={this.checkAllFinished}
          />
        );
      default:
        return (
          <FirstStepComponent
            eventhub={this.props.glEventHub}
            container={this.props.glContainer}
            formatPersonIdFromSeToNoStandart={this.formatPersonIdFromSeToNoStandart}
            formatPersonIdFromNoToSeStandart={this.formatPersonIdFromNoToSeStandart}
            formatPersonIdFromSeToDkStandart={this.formatPersonIdFromSeToDkStandart}
            formatPersonIdFromDkToSeStandart={this.formatPersonIdFromDkToSeStandart}
            isValidPersonId={this.isValidPersonId}
            auth={this.props.auth}
            stepComplete={this.stepComplete}
            match={this.props.match}
            goToNext={this.nextStep}
            allFinished={this.checkAllFinished}
          />
        );
    }
  };
  toggleCancelDialog = () => {
    this.setState({
      visibleCancelDialog: !this.state.visibleCancelDialog,
    });
  };

  formatPersonIdFromSeToNoStandart = value => {
    if (this.isValidPersonId(value, 'se')) {
      var day = value.substring(6, 8);
      var month = value.substring(4, 6);
      var year = value.substring(0, 4);
      return day + month + year;
    }

    return value;
  }

  formatPersonIdFromSeToDkStandart = value => {
    if (this.isValidPersonId(value, 'se')) {
      var day = value.substring(6, 8);
      var month = value.substring(4, 6);
      var year = value.substring(0, 4);
      return day + month + year;
    }

    return value;
  }

  formatPersonIdFromNoToSeStandart = value => {
    if (this.isValidPersonId(value, 'no')) {
      var day = value.substring(0, 2);
      var month = value.substring(2, 4);
      var year = value.substring(4, 8);
      return year + month + day;
    }

    return value;
  }

  formatPersonIdFromDkToSeStandart = value => {
    if (this.isValidPersonId(value, 'dk')) {
      var day = value.substring(0, 2);
      var month = value.substring(2, 4);
      var year = value.substring(4, 8);
      return year + month + day;
    }

    return value;
  }


  isValidPersonId = (personId, country) => {
    return Moment(personId, country === 'se' ? "YYYYMMDD" : country === 'no' ? "DDMMYYYY" : "DDMMYYYY", true).isValid();
  }

  changeCancelReason = e => {
    this.setState({
      cancelReason: e.target.value,
    });
  };

  changeCancelDate = e => {
    this.setState({
      cancelDate: e.value,
    });
  };

  changeRestComment = e => {
    this.setState({
      restComment: e.target.value,
    });
  };

  cancelBooking = obj => {
    const cc = this.props.match.params.countrycode;
    this.setState({
      cancelled: true,
      visibleCancelDialog: !this.state.visibleCancelDialog,
    });
    switch (this.state.cancelReason) {
      case 'resting':
        this.props.naevoSetCaseResting(this.state.restComment, this.state.cancelDate, obj, localStorage.getItem('access_token'));
        this.props.history.replace('/' + cc + '/start');
        break;
      case 'cancelled':
        this.props.naevoSetCaseCancelled(obj, localStorage.getItem('access_token'));
        this.props.history.replace('/' + cc + '/start');
        break;
      case 'phoneCall1':
        this.props.naevoSetCaseStateAsPhoneCall(1, localStorage.getItem('access_token'));
        this.props.history.replace('/' + cc + '/start');
        break;
      case 'phoneCall2':
        this.props.naevoSetCaseStateAsPhoneCall(2, localStorage.getItem('access_token'));
        this.props.history.replace('/' + cc + '/start');
        break;
      case 'phoneCall3':
        this.props.naevoSetCaseStateAsPhoneCall(3, localStorage.getItem('access_token'));
        this.props.history.replace('/' + cc + '/start');
        break;
      default:
        this.props.history.replace('/' + cc + '/start');
        break;
    }
  };

  checkAllFinished = () => {
    const step1 = Object.keys(this.props.stepone).length;
    const step2 = Object.keys(this.props.steptwo).length;
    const step3 = Object.keys(this.props.stepthree).length;
    // console.log(`step lengths: ${step1}, ${step2}, ${step3}`);

    if (step1 > 0 && step2 > 0 && step3 > 0) {
      return true;
    }
    return false;
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div style={{ position: 'fixed', background: '#D3D3D3', zIndex: 999, width: this.state.width }}>
          <Header step={this.state.activeStep} goto={this.goToStep} glContainer={this.props.glContainer} screen2={this.props.screen2} />
          <ButtonComponent
            next={this.nextStep}
            prev={this.prevStep}
            step={this.state.activeStep}
            cancel={this.toggleCancelDialog}
            completedStep={this.state.completedSteps.find(s => s === this.state.activeStep)}
            checkSla={this.checkSla}
            allFinished={this.checkAllFinished}
          />
        </div>
        <div className={classes.toolbar} />
        <div className={classes.toolbar} />
        <br />
        <div className="mainWrapper" id="mainWrapper">
          {this.getStepContent(this.state.activeStep)}

          <div>
            {this.state.visibleCancelDialog && (
              <CancelDialog
                toggleDialog={this.toggleCancelDialog}
                cancelReason={this.state.cancelReason}
                change={this.changeCancelReason}
                cancelBooking={this.cancelBooking}
                cancelDate={this.state.cancelDate}
                restComment={this.state.restComment}
                changeCancelDate={this.changeCancelDate}
                changeRestComment={this.changeRestComment}
                {...this.props}
              />
            )}
          </div>
          <div>
            {this.state.visibleSlaDialog && (
              <SlaDialog
                toggleDialog={this.toggleSlaDialog}
                slaAnswer={this.setSlaAnswer}
                selectedAnswer={this.state.slaAnswer}
                bookCase={this.bookCase}
                {...this.props}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStep: step => dispatch(stepperChange(step)),
    naevoSetCaseResting: (comment, date, answerData, auth) => dispatch(naevoSetCaseResting(comment, date, answerData, auth)),
    naevoSetCaseCancelled: (comment, auth) => dispatch(naevoSetCaseCancelled(comment, auth)),
    naevoSetCaseStateAsPhoneCall: (phonecallno, auth) => dispatch(naevoSetCaseStateAsPhoneCall(phonecallno, auth)),
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MainWindowComponent)
);
