import React from 'react';
// import image from './casecard.png';
export default function CaseCardComponent(props) {
  var urlPrefix = props.match.params.countrycode;
  if (process.env.REACT_APP_ENV == "development" || process.env.REACT_APP_ENV == "staging") {
    urlPrefix = "test-" + urlPrefix;
  }

  return (
    <div>
      <iframe
        src={'https://' + urlPrefix + '.naevo.net/Pages/Case/' + props.match.params.countrycode.toUpperCase() + '/CaseCard.aspx?CaseID=' + props.match.params.caseid}
        frameBorder="0"
        title="caseCard"
        height="1200px"
        width="1000px"
      />

      {/* <img src={image} alt="testImage" width="1000px" /> */}
    </div>
  );
}
