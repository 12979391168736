import React, { Component } from 'react';
import { connect } from 'react-redux';
import { informationQuestionsFetchData, answersFetchData, scriptsFetchData } from '../../../actions/sheetsu';
import { addToSummary, naevoUpdateTaxDeduction } from '../../../actions/naevo';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Button } from '@progress/kendo-react-buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faQuestion, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import './SecondStepComponent.css';
import { scrollTop } from '../../../functions';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getNaevoApiUrl } from '../../../functions';

import Script from '../../Script';
import AnswerScript from './AnswerScript';
import TextFieldComponent from '../../FormComponents/TextFieldComponent';
import NumericFieldComponent from '../../FormComponents/NumericFieldComponent';
import RadioComponent from '../../FormComponents/RadioComponent';
import CheckboxComponent from '../../FormComponents/CheckBoxComponent';
import AttachmentComponent from '../../FormComponents/AttachmentComponent';
import { setStepTwo } from '../../../actions/other';

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

const api_url = getNaevoApiUrl();

class SecondStepComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      answers: [],
      script: [],
      selectedAnswers: [],
      usedAnswers: [],
      user: localStorage.getItem('userEmail'),
    };
  }

  componentDidMount() {
    scrollTop();
    if (this.props.steptwo.length > 0) {
      this.setState({
        selectedAnswers: this.props.steptwo,
      });
    } else if (this.props.qa.length > 0) {
      this.setState({
        selectedAnswers: this.props.qa,
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.addNumericQuestionsToSelectedAnswers();
    this.addTextQuestionsToSelectedAnswers();
    if (prevProps.steptwo !== this.props.steptwo) {
      this.setState({
        selectedAnswers: this.props.steptwo,
      });
    } else if (prevProps.qa !== this.props.qa) {
      this.setState({
        selectedAnswers: this.props.qa,
      });
    }

    if (this.state.selectedAnswers && this.state.selectedAnswers.length < this.props.questions.length) {
      this.updateAnswerCustomProps();
    }
  }

  updateAnswerCustomProps() {
    var { selectedAnswers } = this.state;
    var { questions } = this.props;

    if (!questions || questions.length === 0 || !selectedAnswers || selectedAnswers.length === 0) {
      return;
    }
    selectedAnswers.forEach(ans => {
      var q = questions.find(i => i.question_id === ans.question);

      if (!q || !q.custom1) {
        return;
      }

      if (q.custom1 != ans.custom1) {
        ans.custom1 = q.custom1;
      }
    });
  }

  addEmpty = () => {
    this.addNumericQuestionsToSelectedAnswers();
    this.addTextQuestionsToSelectedAnswers();
  };

  addNumericQuestionsToSelectedAnswers = () => {
    const selectedAnswers = this.state.selectedAnswers;
    this.props.questions.forEach(q => {
      const current = selectedAnswers.find(el => el.question === q.question_id);
      if (!current && q.response_type === 'Numeric') {
        const obj = {
          question: q.question_id,
          qtext: q.question_text,
          atext: 0,
          type: 'numeric'
        };
        selectedAnswers.push(obj);
        this.setState({
          selectedAnswers: selectedAnswers,
        });
      }
    });
  };

  addTextQuestionsToSelectedAnswers = () => {
    const selectedAnswers = this.state.selectedAnswers;
    this.props.questions.forEach(q => {
      const current = selectedAnswers.find(el => el.question === q.question_id);
      if (!current && q.response_type === 'text') {
        const obj = {
          question: q.question_id,
          qtext: q.question_text,
          atext: '',
          type: 'text',
        };
        selectedAnswers.push(obj);
        this.setState({
          selectedAnswers: selectedAnswers,
        });
      }
    });
  };

  allQuestionsAnswered = () => {
    return new Promise((resolve, reject) => {
      let questionsWithoutRequiredAnswer = this.props.questions.filter(q => q.requires_answer === 0 && q.response_type !== "Attachment");
      // console.log('qLength: ', questionsWithoutRequiredAnswer.length);
      // console.log('Selected Answers Length: ', this.state.selectedAnswers.length);
      if (this.state.selectedAnswers.length >= questionsWithoutRequiredAnswer.length) {
        resolve();
      }
      reject();
    });
  };

  stepNext = () => {
    this.allQuestionsAnswered()
      .then(() => {
        this.checkSelectedAnswers().then(() => {
          this.checkRotRut().then(() => {
            this.props.setStepTwo(this.state.selectedAnswers, localStorage.getItem('access_token'));
            this.props.stepComplete(1);
            this.props.goToNext();

          }).catch(err => {
            console.error(err);
          });
        });
      })
      .catch(() => {
        alert('Alle spørsmål må besvares');
      });
  };

  checkboxChange = e => {
    let selectedAnswers = this.state.selectedAnswers;

    if (e.target.checked) {
      const obj = {
        question: parseInt(e.target.getAttribute('data-qid'), 10),
        qtext: e.target.getAttribute('data-qtext'),
        answer: parseInt(e.target.getAttribute('data-aid'), 10),
        atext: e.target.getAttribute('data-atext'),
        script: e.target.getAttribute('data-script'),
        articleId: e.target.getAttribute('data-articleid'),
        type: 'checkbox',
        system: 'booking',
        user: localStorage.getItem('naevoUser'),
      };
      selectedAnswers.push(obj);
    } else {
      selectedAnswers = selectedAnswers.filter(x => x.answer !== parseInt(e.target.getAttribute('data-aid'), 10));
    }

    this.setState({
      selectedAnswers: selectedAnswers
    });
  }

  radioChange = e => {
    const obj = {
      question: parseInt(e.target.getAttribute('data-qid'), 10),
      qtext: e.target.getAttribute('data-qtext'),
      answer: parseInt(e.target.getAttribute('data-aid'), 10),
      atext: e.target.getAttribute('data-atext'),
      script: e.target.getAttribute('data-script'),
      articleId: e.target.getAttribute('data-articleid'),
      type: 'radio',
      system: 'booking',
      user: localStorage.getItem('naevoUser'),
    };
    const selectedAnswers = this.state.selectedAnswers;
    const usedAnswers = this.state.usedAnswers;
    const current = selectedAnswers.find(el => el.question === parseInt(e.target.getAttribute('data-qid'), 10));
    if (current) {
      current.answer = parseInt(e.target.getAttribute('data-aid'), 10);
      current.script = e.target.getAttribute('data-script');
      current.atext = e.target.getAttribute('data-atext');
      current.qtext = e.target.getAttribute('data-qtext');
      current.articleId = e.target.getAttribute('data-articleid');
      current.system = 'booking';
      current.user = localStorage.getItem('naevoUser');
    } else {
      selectedAnswers.push(obj);
      usedAnswers.push(e.target.getAttribute('data-aid'));
    }
    this.setState(
      {
        selectedAnswers: selectedAnswers,
        usedAnswers: usedAnswers,
      },
      () => {
        // console.log(this.state.usedAnswers);
      }
    );
  };

  numericChange = e => {
    const obj = {
      question: e.target.props['data-qid'],
      qtext: e.target.props['data-qtext'],
      atext: e.target.value,
      articleId: e.target.props['data-articleid'],
      type: 'numeric',
      system: 'booking',
      user: localStorage.getItem('naevoUser'),
      answer: e.target.props['data-aid']
    };
    const selectedAnswers = this.state.selectedAnswers;
    const current = selectedAnswers.find(el => el.question === e.target.props['data-qid']);
    if (current) {
      current.atext = e.target.value;
      current.qtext = e.target.props['data-qtext'];
      current.articleId = e.target.props['data-articleid'];
      current.system = 'booking';
      current.user = localStorage.getItem('naevoUser');
      current.answer = e.target.props['data-aid'];
    } else {
      selectedAnswers.push(obj);
    }
    this.setState({
      selectedAnswers: selectedAnswers,
    });
  };

  textChange = e => {
    const obj = {
      question: parseInt(e.target.getAttribute('data-qid'), 10),
      qtext: e.target.getAttribute('data-qtext'),
      atext: e.target.value,
      custom1: e.target.getAttribute('data-custom1'),
      type: 'text',
      system: 'booking',
      user: localStorage.getItem('naevoUser'),
    };
    const selectedAnswers = this.state.selectedAnswers;
    const current = selectedAnswers.find(el => el.question === parseInt(e.target.getAttribute('data-qid'), 10));
    if (current) {
      current.atext = e.target.value;
      current.qtext = e.target.getAttribute('data-qtext');
      current.system = 'booking';
      current.user = localStorage.getItem('naevoUser');
      current.custom1 = e.target.getAttribute('data-custom1');
    } else {
      selectedAnswers.push(obj);
    }
    this.setState({
      selectedAnswers: selectedAnswers,
    });
  };

  checkIfQuestionsRequiresAnswer = question => {
    if (question.requires_answer !== 0) {
      if (this.state.selectedAnswers.find(el => el.answer === question.requires_answer)) {
        return true;
      }
      return false;
    }
    return true;
  };

  checkSelectedAnswers = () => {
    const questions = this.props.questions;
    const selectedAnswers = this.state.selectedAnswers;

    return new Promise(resolve => {
      selectedAnswers.forEach(selan => {
        const qq = questions.find(q => q.question_id === selan.question);

        if (qq && qq.requires_answer) {
          const saq = selectedAnswers.find(sa => sa.answer === qq.requires_answer);
          if (!saq) {
            selectedAnswers.splice(selectedAnswers.indexOf(selan), 1);
            this.setState({
              selectedAnswers: selectedAnswers,
            });
          }
        }
      });
      resolve();
    });
  };

  checkRotRut = () => {
    const selectedAnswers = this.state.selectedAnswers;

    return new Promise(resolve => {
      if (this.props.match.params.countrycode === 'se') {
        const obj = {
          id: this.props.tax.id,
        };
        selectedAnswers.filter(f => f.atext || f.answer).forEach(selan => {
          var question = this.props.questions ? this.props.questions.find(q => q.question_id == selan.question) : null;
          if (question && (question.module == 'GreenDeduction' || question.module == "ROT")) {
            if (question.module == 'GreenDeduction') {
              obj.greenDeduction = true;
            } else if (question.module == "ROT") {
              obj.rot = true;
            }

            if (selan.custom1 === 'FAST') {
              obj.customerDescription = selan.atext;
            } else if (selan.custom1 === 'BRF') {
              obj.brfOrgNo = selan.atext;
            } else if (selan.custom1 === 'APTNO') {
              obj.apartmentNo = selan.atext;
            }
          } else {

            if (question && question.module == 'RUT' && question.custom1 == selan.answer) {
              obj.rut = true;
            }
          }
        });
        this.props.naevoUpdateTaxDeduction(obj, localStorage.getItem('access_token'), true);
        resolve();
      } else {
        resolve();
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="row wrapper" id="secondStepMain">
        <div className="col-xs-12 col-sm-6 offset-sm-3 col">
          <div className="card">
            <div className="card-block">
              <form className="k-form" id="ssForm" onSubmit={this.handleSubmit}>
                <Paper className={classes.paper}>
                  <Script script={2} {...this.props} />
                </Paper>
                <br />
                <Paper className={classes.paper}>
                  <fieldset>
                    <legend>
                      <Trans>Information</Trans>
                    </legend>
                    {this.props.questions
                      .sort((a, b) => a.order - b.order)
                      .map((question, i) => (
                        <div key={i}>
                          {this.checkIfQuestionsRequiresAnswer(question) && (
                            <span>
                              <Paper className={classes.paper} elevation={10} style={{ background: question.module === 'Mersalg' ? '#E0E0E0' : '' }}>
                                <Grid container spacing={24} direction="row" alignItems="center">
                                  <Grid item xs={1} style={{ textAlign: 'center' }}>
                                    <span>
                                      {/* <h3 style={{ display: 'inline' }}>{i + 1}</h3> */}
                                      {question.module === 'Mersalg' && (
                                        <span>
                                          <FontAwesomeIcon icon={faDollarSign} size="lg" />
                                          <FontAwesomeIcon icon={faDollarSign} size="lg" />
                                          <FontAwesomeIcon icon={faDollarSign} size="lg" />
                                        </span>
                                      )}
                                      {question.module === 'ROT' && (
                                        <span>
                                          <FontAwesomeIcon icon={faHandHoldingUsd} size="lg" />
                                        </span>
                                      )}
                                      {question.module === 'RUT' && (
                                        <span>
                                          <FontAwesomeIcon icon={faHandHoldingUsd} size="lg" />
                                        </span>
                                      )}
                                      {question.module === 'GreenDeduction' && (
                                        <span>
                                          <FontAwesomeIcon icon={faHandHoldingUsd} size="lg" />
                                        </span>
                                      )}
                                      {question.module === 'Information' && <FontAwesomeIcon icon={faQuestion} size="lg" />}
                                    </span>
                                  </Grid>
                                  <Grid item xs>
                                    <span className="question">
                                      <legend dangerouslySetInnerHTML={{ __html: question.question_text }}></legend>
                                      <p dangerouslySetInnerHTML={{ __html: question.question_script }}></p>
                                      {question.attachments && question.attachments.length > 0 && (
                                        <div style={{ maxWidth: "500px" }}>
                                          <Carousel>
                                            {question.attachments.map((attachment, i) => (
                                              <div key={i}>
                                                <img src={`${api_url}/File?fileName=${attachment.fileName}&bucketFileName=${attachment.bucketFileName}`} alt={attachment.fileName} />
                                              </div>
                                            ))}
                                          </Carousel>
                                        </div>

                                      )}
                                      <span>
                                        {question.response_type === 'Radio' && (
                                          <RadioComponent
                                            question={question}
                                            answers={this.props.answers}
                                            selected={this.state.selectedAnswers}
                                            radioChange={this.radioChange}
                                          />
                                        )}
                                      </span>
                                      <span>
                                        {question.response_type === 'Text' && (
                                          <TextFieldComponent
                                            question={question}
                                            textChange={this.textChange}
                                            selected={this.state.selectedAnswers}
                                          />
                                        )}
                                      </span>
                                      <span>
                                        {question.response_type === 'Numeric' && (
                                          <NumericFieldComponent
                                            question={question}
                                            numChange={this.numericChange}
                                            selected={this.state.selectedAnswers}
                                            answers={this.props.answers}
                                          />
                                        )}
                                      </span>
                                      <span>
                                        {question.response_type === 'Checkbox' && (
                                          <CheckboxComponent
                                            question={question}
                                            checkboxChange={this.checkboxChange}
                                            selected={this.state.selectedAnswers}
                                            answers={this.props.answers}
                                          />
                                        )}
                                      </span>
                                      <span>
                                        {question.response_type === 'Attachment' && (
                                          <AttachmentComponent
                                            question={question}
                                            selected={this.state.selectedAnswers}
                                          />
                                        )}
                                      </span>
                                      <br />
                                    </span>
                                    {this.state.selectedAnswers.map((sa, i) => (
                                      <span key={i}>
                                        {sa.question === question.question_id && sa.script && (
                                          <span>
                                            <br />
                                            <Paper className={classes.paper} style={{ background: 'lightblue' }}>
                                              <AnswerScript script={sa.script} {...this.props} />
                                            </Paper>
                                          </span>
                                        )}
                                      </span>
                                    ))}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {question.price && (
                                      <span style={{ textAlign: 'center' }}>
                                        <h1>{question.price},-</h1>
                                      </span>
                                    )}
                                  </Grid>
                                </Grid>
                              </Paper>
                              <br />
                            </span>
                          )}
                        </div>
                      ))}
                  </fieldset>
                  <div style={{ textAlign: 'center' }}>
                    <Button primary={true} type="button" onClick={this.stepNext}>
                      <Trans>Save</Trans>
                    </Button>
                  </div>
                </Paper>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SecondStepComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    questions: state.questions,
    information: state.information,
    answers: state.answers,
    scripts: state.scripts,
    summary: state.summary,
    steptwo: state.steptwo,
    client: state.client,
    pendingTasks: state.pendingTasks,
    acase: state.acase,
    tax: state.tax,
    qa: state.qa,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchInformation: source => dispatch(informationQuestionsFetchData(source)),
    fetchAnswers: () => dispatch(answersFetchData()),
    fetchScripts: () => dispatch(scriptsFetchData()),
    addToSummary: (object, name) => dispatch(addToSummary(object, name)),
    setStepTwo: (object, auth) => dispatch(setStepTwo(object, auth)),
    naevoUpdateTaxDeduction: (object, auth, refreshCaseData) => dispatch(naevoUpdateTaxDeduction(object, auth, refreshCaseData))
  };
};

export default withTranslation()(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SecondStepComponent)
  )
);
