import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';
import { pendingTasksReducer } from 'react-redux-spinner';

import { information, mersalg, answers, scripts, questions } from './sheetsu';
import {
  cases,
  acase,
  sources,
  client,
  summary,
  articles,
  allArticles,
  installers,
  partners,
  overview,
  overviewregion,
  resting,
  cancelled,
  kbs,
  comment,
  internalComment,
  booked,
  tax,
  sms
} from './naevo';
import { token } from './auth';
import { loadFirstStepForm, step, stepone, steptwo, stepthree, ctname, sourcename, log, qa } from './other';
import { geopostalcode, geoarea } from './geo';

const combined = combineReducers({
  // loading spinner
  pendingTasks: pendingTasksReducer,
  //sheetsu
  questions,
  information,
  mersalg,
  answers,
  scripts,
  // naevo
  cases,
  acase,
  sources,
  client,
  summary,
  articles,
  allArticles,
  installers,
  overview,
  partners,
  overviewregion,
  resting,
  cancelled,
  kbs,
  comment,
  internalComment,
  booked,
  tax,
  //auth
  token,
  //other
  loadFirstStepForm,
  step,
  stepone,
  steptwo,
  stepthree,
  ctname,
  sourcename,
  log,
  qa,
  //geo
  geoarea,
  geopostalcode,
  sms
});

export default withReduxStateSync(combined);
