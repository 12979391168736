import axios from 'axios';
import { getNaevoApiUrl, getNaevoApiToken } from '../functions';
import a0 from '../Auth/AuthService';

const token = getNaevoApiToken();
const api_url = getNaevoApiUrl();

// ACTIONS
export function formLoadFirstStepFormSuccess(data) {
  return {
    type: 'LOAD_FIRST_STEP_FORM',
    data,
  };
}

export function stepperChangeSuccess(step) {
  return {
    type: 'STEPPER_CHANGE_SUCCESS',
    step,
  };
}

export function setStepOneSuccess(stepone) {
  return {
    type: 'SET_STEP_ONE_SUCCESS',
    stepone,
  };
}

export function setStepTwoSuccess(steptwo) {
  return {
    type: 'SET_STEP_TWO_SUCCESS',
    steptwo,
  };
}

export function setStepThreeSuccess(stepthree) {
  return {
    type: 'SET_STEP_THREE_SUCCESS',
    stepthree,
  };
}

export function caseTypeNameSuccess(ctname) {
  return {
    type: 'CASETYPE_NAME_SUCCESS',
    ctname,
  };
}
export function sourceNameSuccess(sourcename) {
  return {
    type: 'SOURCE_NAME_SUCCESS',
    sourcename,
  };
}
export function getAnsweredQuestionsByCaseSuccess(answers) {
  return {
    type: 'GET_ANSWERED_QUESTIONS',
    answers,
  };
}

export function updateAnsweredQuestionsByCaseSuccess() {
  return {
    type: 'UPDATE_ANSWERED_QUESTIONS',
  };
}

export function insertAnsweredQuestionsByCaseSuccess() {
  return {
    type: 'INSERT_ANSWERED_QUESTIONS',
  };
}

// ACTION CREATORS
export function formLoadFirstStepForm(object) {
  return dispatch => {
    dispatch(formLoadFirstStepFormSuccess(object));
  };
}

export function stepperChange(step) {
  return dispatch => {
    dispatch(stepperChangeSuccess(step));
  };
}

export function setStepOne(object) {
  return dispatch => {
    dispatch(setStepOneSuccess(object));
  };
}
export function setStepTwo(object, auth) {
  return async (dispatch, getState) => {
    const acase = getState().acase;
    axios({
      method: 'GET',
      url: api_url + '/bmAnswerData/' + acase.id,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then(async res => {
      // if (res.data.length > 0) {
      //   await dispatch(updateAnsweredQuestionsByCase(object, auth));
      // } else {
      //   await dispatch(insertAnsweredQuestionsByCase(object, auth));
      // }
      await dispatch(updateAnsweredQuestionsByCase(object, auth));
      return res.data;
    });
    dispatch(setStepTwoSuccess(object));
  };
}
export function setStepThree(object) {
  return dispatch => {
    dispatch(setStepThreeSuccess(object));
  };
}
/**
 * ACTION CREATOR
 * Get Saved Answers For Current Case
 * @export
 * @param {*} auth Auth token
 * @returns Answers
 */
export function getAnsweredQuestionsByCase(q, auth) {
  return async (dispatch, getState) => {
    const acase = getState().acase;
    // const questions = getState().questions;
    axios({
      method: 'GET',
      url: api_url + '/bmAnswerData/' + acase.id,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(async res => {
        // if (res.length === 0 && q.length > 0) {
        //   dispatch(insertAnsweredQuestionsByCase(q, auth));
        // }
        return res.data;
      })
      .then(answers => dispatch(getAnsweredQuestionsByCaseSuccess(answers)));
  };
}

export function updateAnsweredQuestionsByCase(q, auth) {
  return (dispatch, getState) => {
    const acase = getState().acase;
    axios({
      method: 'POST',
      url: api_url + '/bmSaveAnswerData/' + acase.id,
      data: q,
      headers: {
        Authorization: 'Bearer ' + token,
        EmployeeId: `${a0.getNaevoUserId()}`
      },
    })
      .then(res => {
        dispatch(getAnsweredQuestionsByCase(q, auth));
        return res.data;
      })
      .then(dispatch(updateAnsweredQuestionsByCaseSuccess()));
  };
}

// export function insertAnsweredQuestionsByCase(q, auth) {
//   return (dispatch, getState) => {
//       const acase = getState().acase;
//     axios({
//       method: 'POST',
//       url: api_url + '/pg/information/' + acase.id,
//       data: q,
//       headers: {
//         Authorization: 'Bearer ' + auth,
//       },
//     })
//       .then(res => {
//         dispatch(getAnsweredQuestionsByCase(q, auth));
//         return res.data;
//       })
//       .then(dispatch(insertAnsweredQuestionsByCaseSuccess()));
//   };
// }
