import React from 'react';

export default function AnswerScript(props) {
  const replaceList = () => {
    return {
      '[CASEID]': props.match.params.caseid
    };
  };

  const replaceText = script => {
    let newScript = script;
    const list = replaceList();
    Object.keys(list).forEach(element => {
      if (newScript.indexOf(element) > -1) {
        newScript = newScript.replace(element, list[element]);
      }
    });
    return newScript;
  };

  return (
    <strong
      dangerouslySetInnerHTML={{
        __html: replaceText(props.script),
      }}
    />

  );
}
