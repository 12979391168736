import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    width: '90%',
    padding: 0,
  },
  stepper: {
    background: 'transparent',
  },
  labels: {
    // color: 'black',
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  step: {
    color: '#808080',
    '&$completed': {
      color: '#7BBF6B',
    },
    '&$active': {
      color: '#8EBDFF',
    },
    '&$disabled': {
      color: 'blue',
    },
  },
  active: {
    color: '#8EBDFF',
  },
  completed: {
    color: '#7BBF6B',
  },
  disabled: {},
});

function getSteps() {
  return ['Quality Assurance', 'Information', 'Time Booking', 'Summary'];
}

class HeaderStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  goToStep = step => {
    this.props.goto(step);
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { step } = this.props;
    return (
      <div className={classes.root}>
        <Stepper className={classes.stepper} activeStep={step} alternativeLabel>
          {steps.map((label, i) => (
            <Step className={classes.labels} key={label} onClick={() => this.goToStep(i)} style={{ cursor: 'pointer' }}>
              <StepLabel
                className={classes.labels}
                StepIconProps={{
                  classes: {
                    root: classes.step,
                    completed: classes.completed,
                    active: classes.active,
                  },
                }}
              >
                <span style={{ color: 'white' }}>
                  <strong>
                    <Trans>{label}</Trans>
                  </strong>
                </span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}

HeaderStepper.propTypes = {
  classes: PropTypes.object,
};

export default withTranslation()(withStyles(styles)(HeaderStepper));
