import React, { createRef } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import EmptySlotComponent from './EmptySlotComponent';
import BookedSlotComponent from './BookedSlotComponent';
import ReservedComponent from './ReservedComponent';

import 'moment/locale/sv';
import 'moment/locale/nb';
import AvailabilityGridComponent from './AvailabilityGridComponent';

const styles = theme => ({
  card: {
    minWidth: 275,
    background: 'lightgrey',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  redText: {
    color: 'red',
  },
  bar: {
    background: '#FF5043',
  },
});

const openMap = link => {
  window.open(link, '_blank');
};

function EmployeeOverviewComponent(props) {
  // const { days } = props.employee;
  let employees = props.overview[0].employees.length > 0 ? props.overview[0].employees : 0;
  const { classes } = props;
  const { t } = useTranslation();
  const cc = props.match.params.countrycode;

  const checkSlotAvailability = (day, slot) => {
    const articles = props.articles;
    let caseEstimate = 0;
    let reservation = 0;
    articles.forEach(article => {
      const est = article.unitWorkEstimateBooking === '' ? 0 : +article.unitWorkEstimateBooking;
      caseEstimate = caseEstimate + est * article.quantity;
    });
    if (day.reservations.length > 0) {
      day.reservations.map(r => {
        if (r.time === slot.slot) {
          reservation = +r.minutes;
        }
      });
    }
    const sum = +slot.totalEstimatedBookingWork + reservation;
    if (sum + +caseEstimate >= slot.totalSlotTime) {
      return { estimate: caseEstimate, full: true, reservation: reservation };
    } else {
      return { estimate: caseEstimate, full: false, reservation: reservation };
    }
  };

  const saveBookingDetails = (day, slot, employee) => {
    props.finished({
      caseid: props.acase.id,
      employeeid: employee,
      date: day.date,
      slot: slot.slot,
    });
  };

  const saveBookingKbs = employee => {
    props.finished({
      caseid: props.acase.id,
      employeeid: employee,
      kbs: true,
    });
  };

  const refs = {};

  const handleClick = id => {
    refs[id].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div>
      <AvailabilityGridComponent
        overview={props.overview}
        articles={props.articles}
        employees={employees}
        acase={props.acase}
        handleClick={handleClick}
      />

      {employees.length > 0 &&
        employees.map((e, i) => {
          return (
            <span key={i}>
              {/* <EmployeeOverviewComponent finished={props.finished} employee={e} {...props} />
              <br /> */}
              <Paper className={classes.paper}>
                <strong>{e.employeeName}</strong>
                <br />
                {e.days &&
                  e.days.map((d, index) => {
                    return (
                      <span key={d.date} ref={el => (refs[e.employeeId + d.date] = el)}>
                        <Paper className={classes.paper} elevation={8} style={index % 2 > 0 ? { background: 'white' } : { background: '#e6e6e6' }}>
                          <Grid container spacing={8}>
                            <Grid item md={9}>
                              <h2>
                                {cc === 'se' && (
                                  <Moment format="dddd YYYY-MM-DD" locale="sv">
                                    {d.date}
                                  </Moment>
                                )}
                                {cc === 'no' && (
                                  <Moment format="dddd YYYY-MM-DD" locale="nb">
                                    {d.date}
                                  </Moment>
                                )}
                                {cc === 'dk' && (
                                  <Moment format="dddd YYYY-MM-DD" locale="da">
                                    {d.date}
                                  </Moment>
                                )}
                              </h2>
                            </Grid>
                            <Grid item md={3} style={{ textAlign: 'right' }}>
                              {d.googleLinkWholeDay !== null && (
                                <h3 onClick={() => openMap(d.googleLinkWholeDay)} style={{ cursor: 'pointer' }}>
                                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                                  &nbsp;&nbsp;
                                  {t('Show on map')}
                                </h3>
                              )}
                            </Grid>
                          </Grid>
                          <p>{d.comment}</p>

                          {d.slots.map((slot, i) => (
                            <span key={i}>
                              <Grid container spacing={8}>
                                <Grid item md={2}>
                                  <h3>{slot.slot}</h3>
                                </Grid>
                                <Grid item md={8} style={{ textAlign: 'center' }}>
                                  {checkSlotAvailability(d, slot).full && (
                                    <h3 style={{ color: 'orange' }}>
                                      <FontAwesomeIcon icon={faExclamationTriangle} />
                                      &nbsp;&nbsp;
                                      {t('This slot seems to be full')} {t('Contact installer to verify')}
                                    </h3>
                                  )}
                                  {d.isRedDate === true && (
                                    <h3 style={{ color: 'red' }}>
                                      <FontAwesomeIcon icon={faExclamationTriangle} />
                                      &nbsp;&nbsp;
                                      {t('Non-working day')}. {t('Contact installer to verify')}
                                    </h3>
                                  )}
                                </Grid>
                                <Grid item md={2} style={{ textAlign: 'right' }}>
                                  {slot.googleLink !== null && (
                                    <h4 onClick={() => openMap(slot.googleLink)} style={{ cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={faMapMarkedAlt} />
                                      &nbsp;&nbsp;
                                      {t('Show on map')}
                                    </h4>
                                  )}
                                </Grid>
                              </Grid>
                              {d.reservations.map((reservation, ii) => (
                                <span key={ii}>
                                  {reservation.time === slot.slot && <ReservedComponent reservation={reservation} />}
                                  {reservation.time === '' && <ReservedComponent reservation={reservation} />}
                                </span>
                              ))}
                              {slot.cases.map((c, iii) => (
                                <span key={iii}>{slot.cases.length > 0 && <BookedSlotComponent case={c} slot={slot} sourceCaseTypeName={props.acase.sourceCaseTypeName} />}</span>
                              ))}
                              <span>
                                <EmptySlotComponent
                                  slot={slot}
                                  case={props.acase}
                                  client={props.client}
                                  day={d}
                                  sourcename={props.sourcename}
                                  checkSlotAvailability={checkSlotAvailability}
                                  saveBookingDetails={saveBookingDetails}
                                  saveBookingKbs={saveBookingKbs}
                                  geo={props.geopostalcode}
                                  overview={props.overview}
                                  employee={e.employeeId}
                                />
                              </span>
                              <br />
                              <Grid container spacing={8}>
                                <Grid item md={12} style={{ textAlign: 'right' }}>
                                  <strong>
                                    {d.reservations.length > 0 &&
                                      d.reservations.map((r, ii) => (
                                        <span key={ii}>
                                          {r.time === slot.slot &&
                                            (slot.totalEstimatedBookingWork !== null ? +slot.totalEstimatedBookingWork + +r.minutes : 0 + +r.minutes)}
                                        </span>
                                      ))}
                                    {/* {d.reservations.length === 0 && (slot.totalEstimatedBookingWork !== null ? +slot.totalEstimatedBookingWork : 0)}/
                          {slot.totalSlotTime} */}
                                    {d.reservations.length === 0 && (slot.totalEstimatedBookingWork !== null ? +slot.totalEstimatedBookingWork : 0)} /
                                    {slot.totalSlotTime}
                                  </strong>
                                </Grid>
                              </Grid>
                            </span>
                          ))}
                        </Paper>
                        <br />
                      </span>
                    );
                  })}
              </Paper>
            </span>
          );
        })}
    </div>
  );
}

EmployeeOverviewComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmployeeOverviewComponent);
