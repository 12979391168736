import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Moment from 'react-moment';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    maxHeight: '230px',
    overflowY: 'scroll'
  },
  gridList: {
    height: 'auto',
    width: 'auto',
    overflow: 'hidden',
    textAlign: 'center',
  },
  tile: {
    // padding: '5px',
  },
});

const legends = [
  {
    label: 'Available slots',
    color: '#A8D18E'
  },
  {
    label: 'Same zip',
    color: '#3CB371'
  },
  {
    label: 'Full slots',
    color: '#D0CECE'
  },
  {
    label: 'Prelled slot',
    color: '#3f51b5'
  }
];

function AvailabilityGridComponent(props) {
  const caseEstimate = () => {
    let caseEstimate = 0;

    props.articles.forEach(article => {
      const est = article.unitWorkEstimateBooking === '' ? 0 : +article.unitWorkEstimateBooking;
      caseEstimate = caseEstimate + est * article.quantity;
    });
    return caseEstimate;
  };

  const estimate = caseEstimate();
  const checkDayAvailability = (day, employeeId) => {
    const client = props.acase.client;
    const slots = day.slots.length;
    let full = 0;
    let sameZip = false;
    day.slots.forEach(slot => {
      const estimatedBooking = +slot.totalEstimatedBookingWork !== null ? +slot.totalEstimatedBookingWork : 0;
      const used = checkReservationPerSlot(day, slot) + estimatedBooking + +estimate;
      const slotTime = slot.totalSlotTime;
      slot.cases.forEach(c => {
        const slotFirst3Zip = c.address.match(/.{1,3}/g)[0];
        const clientFirst3Zip = client.installAddressZip.match(/.{1,3}/g)[0];
        // console.log(`slotZip: ${slotFirst3Zip} | clientZip: ${clientFirst3Zip}`);

        if (slotFirst3Zip === clientFirst3Zip) sameZip = true;
      });
      if (used > slotTime) {
        full = full + 1;
      }
    });

    if (props.acase.installerId === employeeId && moment(props.acase.installDate).isSame(moment(day.date).format("YYYY-MM-DD"))) {
      return { background: '#3f51b5', textAlign: 'center', border: '1px solid grey', cursor: 'pointer' };
    }

    if (slots > full) {
      let style = { background: '#A8D18E', textAlign: 'center', border: '1px solid grey', cursor: 'pointer' };
      if (sameZip) {
        style = { background: '#3CB371', textAlign: 'center', border: '1px solid grey', cursor: 'pointer' };
      }
      return style;
    } else if (slots <= full) {
      return { background: '#D0CECE', textAlign: 'center', border: '1px solid grey', cursor: 'pointer' };
    }
  };

  const checkReservationPerSlot = (day, slot) => {
    let reservation = 0;
    day.reservations.forEach(res => {
      if (res.time === slot.slot) {
        reservation = +res.minutes;
      } else if (res.time === '' && res.minutes === '0') {
        reservation = 9999;
      }
    });
    return reservation;
  };

  const { classes } = props;
  const { days } = props.overview[0].employees.length > 0 ? props.overview[0].employees[0] : 0;
  const { t } = useTranslation();

  return (
    <div style={{ position: '-webkit-sticky', position: 'sticky', top: '55px', zIndex: 1, maxHeight: '250px' }}>
      {days && days.length > 0 && (
        <Paper className={classes.paper}>
          <div>
            {legends.map(legend => (
              <span style={{
                marginRight: '10px',
                fontWeight: 'bold'
              }}>
                <span style={{
                  marginRight: '5px',
                  border: '1px solid #ccc',
                  display: 'inline-block',
                  width: '12px',
                  height: '12px',
                  top: '2px',
                  position: 'relative',
                  backgroundColor: legend.color
                }}>
                </span>
                {t(legend.label)}
              </span>
            ))}
          </div>
          {props.employees.map(e => (
            <span key={e}>
              <strong>{e.employeeName}</strong>
              <GridList cellHeight={35} className={classes.gridList} cols={days.length} spacing={2}>
                {e.days.map((d, i) => (
                  <GridListTile
                    key={d.date}
                    cols={1}
                    className={classes.tile}
                    style={checkDayAvailability(d, e.employeeId)}
                    onClick={() => props.handleClick(e.employeeId + d.date)}
                  >
                    <span style={{ textAlign: 'center', verticalAlign: 'middle', color: 'white' }}>
                      <strong>
                        <Moment format="dd" style={{ textTransform: 'uppercase', paddingRight: '5px' }}>
                          {d.date}
                        </Moment>
                      </strong>
                      (<Moment format="DD-MM">{d.date}</Moment>)
                    </span>
                    {/* <Tooltip title={d.date}>
                      <div style={{ height: '100%', width: '100%' }} />
                    </Tooltip> */}
                  </GridListTile>
                ))}
              </GridList>
            </span>
          ))}
        </Paper>
      )}
    </div>
  );
}

export default withStyles(styles)(AvailabilityGridComponent);
