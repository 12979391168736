import React, { Component, Suspense } from 'react';
import { Provider } from 'react-redux';
import { connect } from 'react-redux';
import { store } from './store/index';
import { Spinner } from 'react-redux-spinner';

import a0 from './Auth/AuthService';
import './App.css';
import GoldenLayoutWrapper from './GoldenLayout';
import { naevoGetCases, naevoGetCase, naevoGetSources, naevoGetClient } from './actions/naevo';
import { informationQuestionsFetchData, mersalgQuestionsFetchData, scriptsFetchData, answersFetchData } from './actions/sheetsu';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getCaseIdFromLocation() {
    return this.props.match.params.caseid;
  }
  componentDidMount() {
    const login = async () => {
      if (!a0.isAuthenticated() || !await a0.checkIfUserExists(localStorage.getItem('userEmail'))) {
        a0.login();
      }
    };
    login().then(res => {
      const token = localStorage.getItem('access_token');
      const getCaseAndSource = async () => {
        await this.props.fetchCase(this.getCaseIdFromLocation(), token);
      };
      getCaseAndSource().then(() => { });
    });

    if (this.props.match.params.countrycode === "se") {
      window.Beacon('init', 'eec4a84b-9839-4cfb-9e3a-19cb08208ad1');
    } else {
      window.Beacon('init', 'f74eb308-ceb9-4c0c-8d4b-87c6e456eda8');
    }
  }

  render() {
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Provider store={store}>
            <Spinner />
            <GoldenLayoutWrapper store={store} history={this.props.history} auth={a0} {...this.props} />
          </Provider>
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cases: state.cases,
    acase: state.acase,
    sources: state.sources,
    client: state.client,
    pendingTasks: state.pendingTasks,
    geopostalcode: state.geopostalcode,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCases: (source_id, state, auth) => dispatch(naevoGetCases(source_id, state, auth)),
    fetchCase: (case_id, auth) => dispatch(naevoGetCase(case_id, auth)),
    fetchSources: auth => dispatch(naevoGetSources(auth)),
    fetchInformation: (source, casetype, auth) => dispatch(informationQuestionsFetchData(source, casetype, auth)),
    fetchMersalg: (source, casetype, auth) => dispatch(mersalgQuestionsFetchData(source, casetype, auth)),
    fetchAnswers: (source, casetype, auth) => dispatch(answersFetchData(source, casetype, auth)),
    fetchScripts: auth => dispatch(scriptsFetchData(auth)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
