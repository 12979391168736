import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SummaryStepOneComponent(props) {
  const { t } = useTranslation();
  return (
    <div>
      {props.stepone.name && (
        <span>
          <fieldset>
            <legend>
              {/* <Trans>Quality Assurance</Trans> */}
              {t('Quality Assurance')}
            </legend>
            <span>
              {props.stepone.name && (
                <span>
                  <p>
                    <strong>
                      {/* <Trans>Name</Trans> */}
                      {t('Name')}
                    </strong>
                  </p>
                  <span>{props.stepone.name}</span>
                </span>
              )}
            </span>
            <span>
              {props.stepone.installAddressStreet && (
                <p>
                  <strong>
                    {/* <Trans>Installation Address</Trans> */}
                    {t('Installation Address')}
                  </strong>
                </p>
              )}
              {props.stepone.installAddressStreet && <span>{props.stepone.installAddressStreet}</span>}
              {props.stepone.installAddressZip && <span>, {props.stepone.installAddressZip}</span>}
              {props.stepone.installAddressCity && <span>, {props.stepone.installAddressCity}</span>}
            </span>
            <span>
              {props.stepone.invoiceAddressStreet && (
                <p>
                  <strong>
                    {/* <Trans>Invoice Address</Trans> */}
                    {t('Invoice Address')}
                  </strong>
                </p>
              )}
              {props.stepone.invoiceAddressStreet && <span>{props.stepone.invoiceAddressStreet}</span>}
              {props.stepone.invoiceAddressZip && <span>, {props.stepone.invoiceAddressZip}</span>}
              {props.stepone.invoiceAddressCity && <span>, {props.stepone.invoiceAddressCity}</span>}
            </span>
            <span>
              {/* {props.stepone.email && (
                <p>
                  <strong>
                    {t('Contact Information')}
                  </strong>
                </p>
              )} */}
              {props.stepone.email && (
                <span>
                  <p>
                    <strong>
                      {/* <Trans>Email</Trans> */}
                      {t('Email')}
                    </strong>
                  </p>
                  <p>{props.stepone.email}</p>
                </span>
              )}
              {props.stepone.phone1 && (
                <span>
                  <p>
                    <strong>
                      {/* <Trans>Phone Number</Trans>(<Trans>Primary</Trans>) */}
                      {t('Phone Number')}({t('Primary')})
                    </strong>
                  </p>
                  <p>{props.stepone.phone1}</p>
                </span>
              )}
              {props.stepone.phone2 && (
                <span>
                  <p>
                    <strong>
                      {/* <Trans>Phone Number</Trans>(<Trans>Secondary</Trans>) */}
                      {t('Phone Number')}({t('Secondary')})
                    </strong>
                  </p>
                  <p>{props.stepone.phone2}</p>
                </span>
              )}
              {props.stepone.personId && (
                <span>
                  <p>
                    <strong>
                      {/* <Trans>Personal Identity Number</Trans> */}
                      {t('Personal Identity Number')}
                    </strong>
                  </p>
                  {props.countrycode === 'se' && (<p>{props.stepone.personId + '-' + props.stepone.ssn}</p>)}
                  {props.countrycode === 'dk' && (<p>{props.formatPersonIdFromSeToDkStandart(props.stepone.personId) + '-' + props.stepone.ssn}</p>)}
                  {props.countrycode === 'no' && (<p>{props.formatPersonIdFromSeToNoStandart(props.stepone.personId) + '-' + props.stepone.ssn}</p>)}
                </span>
              )}
            </span>
          </fieldset>
        </span>
      )}
    </div>
  );
}
