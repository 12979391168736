import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { getNaevoApiUrl } from '../../functions';

const api_url = getNaevoApiUrl();

export default function AttachmentComponent(props) {
    const selected = props.selected.filter(el => el.question === props.question.question_id);
    const images = selected.filter(x => x.attachment && x.attachment.isImage);
    const documents = selected.filter(x => x.attachment && !x.attachment.isImage);
    return (
        <div>
            <span>
                <h3>Attached images</h3>
                {images && images.length > 0 && (
                    <div style={{ maxWidth: "500px" }}>
                        <Carousel>
                            {images.map((answer, i) => (
                                <div key={i}>
                                    <img src={`${api_url}/File?fileName=${answer.attachment.fileName}&bucketFileName=${answer.attachment.bucketFileName}`} alt={answer.attachment.fileName} />
                                </div>
                            ))}
                        </Carousel>
                    </div>

                )}
                <h3>Attached documents</h3>
                {documents && documents.length > 0 && (
                    <div>
                        {documents.map((answer, i) => (
                            <a key={i} href={`${api_url}/File?fileName=${answer.attachment.fileName}&bucketFileName=${answer.attachment.bucketFileName}`} download>{answer.attachment.fileName}</a>
                        ))}
                    </div>
                )}
            </span>
        </div>
    );
}
