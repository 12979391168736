import React from 'react';
import './HistoryComponent.css';
export default function HistoryComponent() {
  return (
    <div style={{ background: 'white', height: '100%' }}>
      <table className="historyTable" width="100%">
        <tbody>
          <tr>
            <td>2011-01-01</td>
            <td>08:31</td>
            <td>
              <span style={{ background: 'red' }}>TESTER</span>
            </td>
            <td>Webservice</td>
          </tr>
          <tr>
            <td>2011-01-02</td>
            <td>08:31</td>
            <td>
              <span>TESTER</span>
            </td>
            <td>Webservice</td>
          </tr>
          <tr>
            <td>2011-01-03</td>
            <td>08:31</td>
            <td>
              <span style={{ background: 'green' }}>TESTER</span>
            </td>
            <td>Webservice</td>
          </tr>
          <tr>
            <td>2011-01-04</td>
            <td>08:31</td>
            <td>
              <span style={{ background: 'orange' }}>TESTER</span>
            </td>
            <td>Webservice</td>
          </tr>
          <tr>
            <td>2011-01-05</td>
            <td>08:31</td>
            <td>
              <span style={{ background: 'blue' }}>TESTER</span>
            </td>
            <td>Webservice</td>
          </tr>
          <tr>
            <td>2011-01-06</td>
            <td>08:31</td>
            <td>
              <span style={{ background: 'green' }}>TESTER</span>
            </td>
            <td>Webservice</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
