export const NAEVO_CASE_STATE = {
    ORDERED: 1,
    PRELIMINAR: 2,
    BOOKED: 3,
    DONE: 4,
    RESTING: 6,
    PHONE_CALL_1: 36,
    PHONE_CALL_2: 37,
    PHONE_CALL_3: 39,
    SELFSERVICE: 99
}

export const FEEDBACK_QUESTION_SPECIAL_TRIGGERS = {
    NONE: 0,
    SLA_DATE: 1,
    CONTROLLED: 2,
    INSTALL_DATE: 4,
    INSTALL_TIME: 8,
    SLA_ONSITE_DATE: 16
}

export const FEEDBACK_QUESTION_MODULE_TYPES = {
    NONE: 0,
    INFORMATION: 1,
    MERSALG: 2,
    ROT: 3,
    RUT: 4,
    GREEN_DEDUCTION: 5
}