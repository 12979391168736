import axios from 'axios';
import { getNaevoApiUrl, getNaevoApiToken } from '../functions';

const token = getNaevoApiToken();
const api_url = getNaevoApiUrl();

// ACTIONS
export function geoCheckPostalCodeSuccess(geopostalcode) {
  return {
    type: 'GEO_CHECK_POSTALCODE_SUCCESS',
    geopostalcode,
  };
}
export function geoCheckAreaSuccess(geoarea) {
  return {
    type: 'GEO_CHECK_AREA_SUCCESS',
    geoarea,
  };
}

function checkArea(areaid, auth) {
  return axios({
    method: 'GET',
    url: `${api_url}/getArea/${areaid}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.data);
}

// ACTION CREATORS
export function geoCheckPostalCode(installers, partners, cc, postalcode, auth) {
  return (dispatch, getState) => {
    const acase = getState().acase;
    const pcode = postalcode.replace(/\s/g, '');
    axios({
      method: 'GET',
      url: `${api_url}/checkPostalCode/${cc}/${postalcode}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        const data = res.data;
        data.details = !data.details ? [] : data.details;
        let promises = [];
        if (data.areas && data.areas.length > 0) {
          data.areas.forEach(area => {
            promises.push(checkArea(area.area_id, auth));
          });
          Promise.all(promises).then(el => {
            el.forEach(detail => {
              const stripTags = detail.tags.filter(res => res.split(' (')[0] === acase.productFamilyName);
              if (stripTags.length > 0) {
                if (cc === 'se' || cc === 'dk') {
                  const detailName = detail.name.split(',');
                  detailName.forEach(async name => {
                    detail.installers = detail.installers ? detail.installers : [];
                    const newName = name.trim();

                    // const partnerName = newName.split('-')[0];
                    // detail.partner = partners.filter(partner => partner.name.split(' ')[0].split('-')[0] === partnerName);
                    // await detail.partner.map(p => {
                    //   detail.partner.installer = installers.filter(installer => installer.partnerId === +p.id);
                    // });
                    // await detail.partner.installer.forEach(pi => {
                    //   if (pi.name.includes(newName)) {
                    //     detail.installers.push(pi);
                    //   }
                    // });
                    await installers.forEach(i => {
                      const nn = i.name.split(' ')[0].trim();
                      if (nn === newName) {
                        detail.installers.push(i);
                      }
                    });
                  });
                  detail.partner = [];
                } else if (cc === 'no') {
                  const detailName = detail.name.split(',');
                  detailName.forEach(name => {
                    const newName = name.trim();
                    const splitted = newName.split(':')[0].trim();
                    detail.partner = partners.filter(partner => partner.id === +splitted);
                    // detail.partner.installer = installers.filter(installer => installer.partnerId === +splitted);
                  });
                }
                data.details.push(detail);
              }
            });

            dispatch(geoCheckPostalCodeSuccess(data));
          });
        } else {
          data.areas = [];
        }
        return data;
      })
      .then(geopostalcode => dispatch(geoCheckPostalCodeSuccess(geopostalcode)));
  };
}

export function geoCheckArea(areaid, auth) {
  return dispatch => {
    axios({
      method: 'GET',
      url: `${api_url}/getArea/${areaid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
      .then(geoarea => dispatch(geoCheckAreaSuccess(geoarea)));
  };
}
