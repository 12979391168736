import React, { Component } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
class CaseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const style = {
      height: '100%',
      background: 'white',
    };

    const { acase } = this.props;

    return (
      <div style={style}>
        <span>
          {this.props.sources.map((s, i) => (
            <span key={i}>
              {s.id === acase.sourceId && (
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <p>
                      <strong>
                        <Trans>Source</Trans>
                      </strong>
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{s.name}</p>
                  </Grid>
                </Grid>
              )}
              {s.caseTypes.map((ct, i) => (
                <span key={i}>
                  {ct.id === acase.sourceCaseTypeId && (
                    <Grid container spacing={8}>
                      <Grid item xs={6}>
                        <p>
                          <strong>
                            <Trans>Casetype</Trans>
                          </strong>
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>{ct.name}</p>
                      </Grid>
                    </Grid>
                  )}
                </span>
              ))}
            </span>
          ))}
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <p>
                <strong>
                  <Trans>Recieved</Trans>
                </strong>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>{acase.receiveDate}</p>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <p>
                <strong>
                  <Trans>Status</Trans>
                </strong>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>{acase.stateName}</p>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <p>
                <strong>
                  <Trans>Info A</Trans>
                </strong>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>{acase.infoA}</p>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <p>
                <strong>
                  <Trans>Info B</Trans>
                </strong>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>{acase.infoB}</p>
            </Grid>
          </Grid>
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CaseComponent)
);
