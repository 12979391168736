import React, { Component } from 'react';
// import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import { connect } from 'react-redux';

import './MapsComponent.css';

class MapComponent extends Component {
  // test = () => {
  //   const client = new Promise(resolve => {
  //     resolve(this.props.acase[0].client);
  //   });
  //   client.then(c => {
  //     console.log(this.props.acase[0].client);
  //     const address =
  //       this.props.acase[0].client.installAddressName +
  //       ' ' +
  //       this.props.acase[0].client.installAddressZip +
  //       ' ' +
  //       this.props.acase[0].client.installAddressCity;
  //     return encodeURI(address);
  //   });
  // };

  render() {
    // this.test();
    return (
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            title="tester"
            width="100%"
            height="100%"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Gneisveien%2018&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapComponent);

// import React from 'react';
// import { compose, withProps } from 'recompose';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

// const MyMapComponent = compose(
//   withProps({
//     googleMapURL: 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places',
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `100%` }} />,
//     mapElement: <div style={{ height: `100%` }} />,
//   }),
//   withScriptjs,
//   withGoogleMap
// )(props => (
//   <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
//     {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} onClick={props.onMarkerClick} />}
//   </GoogleMap>
// ));

// export default class MyFancyComponent extends React.PureComponent {
//   state = {
//     isMarkerShown: false,
//   };

//   componentDidMount() {
//     this.delayedShowMarker();
//   }

//   delayedShowMarker = () => {
//     setTimeout(() => {
//       this.setState({ isMarkerShown: true });
//     }, 3000);
//   };

//   handleMarkerClick = () => {
//     this.setState({ isMarkerShown: false });
//     this.delayedShowMarker();
//   };

//   render() {
//     return <MyMapComponent isMarkerShown={this.state.isMarkerShown} onMarkerClick={this.handleMarkerClick} />;
//   }
// }
