import React, { Component } from 'react';
import logo from './Inselo Logo white.png';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { naevoGetCase } from './actions/naevo';

import a0 from './Auth/AuthService';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class Start extends Component {
  componentDidMount() {
    const login = async () => {
      if (!a0.isAuthenticated() || !await a0.checkIfUserExists(localStorage.getItem('userEmail'))) {
        a0.login();
      }
    };

    login();
  }
  render() {
    const handleKeyPress = e => {
      if (e.key === 'Enter') {
        const cc = this.props.match.params.countrycode;
        const val = e.target.value;
        this.props.history.replace('/' + cc + '/' + val + '/0');
        window.location.reload();
        this.props.fetchCase(val, localStorage.getItem('access_token'));
      }
    };

    const { classes } = this.props;

    const selectCountry = () => {
      a0.logout();
      this.props.history.replace('/');
    };
    return (
      <div style={{ height: '100vh', width: '100%', background: '#FF5043' }}>
        <div style={{ position: 'absolute', left: '50%', right: '50%', top: '300px', marginLeft: '-150px' }}>
          <img src={logo} alt="logo" width="300px" />
          <div style={{ position: 'relative' }}>
            <br />
            <br />
            <TextField
              id="outlined-uncontrolled"
              label="Case ID"
              defaultValue=""
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: '280px', padding: 0 }}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <br />
        <br />
        <div style={{ position: 'relative', top: '500px' }}>
          <button className="buttonLink" type="button" onClick={selectCountry}>
            Select Country
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCase: (case_id, auth) => dispatch(naevoGetCase(case_id, auth)),
  };
};

export default withStyles(styles)(
  withTranslation()(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Start)
  )
);
