import React, { Component } from 'react';
import '@progress/kendo-theme-material/dist/all.css';
import { connect } from 'react-redux';

import './FirstStepComponent.css';
import { addToSummary, naevoChangeClientState, naevoUpdateClient } from '../../../actions/naevo';
import FirstStepForm from './FirstStepForm';
import { setStepOne } from '../../../actions/other';
import { scrollTop } from '../../../functions';

class FirstStepComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      answers: [],
      scripts: [],
      mersalg: [],
      inputDeliveryAddress: {
        checked: false,
      },
      inputInvoiceAddress: {
        checked: false,
      },
      inputMailAddress: {
        checked: false,
      },
      inputPhoneNumber: {
        checked: false,
      },
      inputPId: {
        checked: false,
      },
      success: false,

      formData: [],
      switchPhone: false,
      validpersonIdNumber: true,
      validSSN: true
    };
  }

  componentDidMount() {
    scrollTop();
  }

  handleChange = event => {

    this.props.naevoChangeClientState(client);

    if (event.target.name === "personId") {
      var client = this.props.client[0];
      client.personId = event.target.value;
      this.props.naevoChangeClientState(client);
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    // console.log(this.props.acase);
    const formData = new FormData(e.target);
    let data = Array.from(formData.entries());
    data = data.reduce(
      (memo, pair) => ({
        ...memo,
        [pair[0]]: pair[1],
      }),
      {}
    );

    if (this.validateData(data)) {
      // data.ssn = data.personId.split('-')[1];
      // data.personId = data.personId.split('-')[0];
      if (this.props.match.params.countrycode === 'no') {
        data.personId = this.props.formatPersonIdFromNoToSeStandart(data.personId)
      }

      if (this.props.match.params.countrycode === 'dk') {
        data.personId = this.props.formatPersonIdFromDkToSeStandart(data.personId)
      }

      data = {
        name: 'step1',
        data: data,
      };

      await this.props.naevoUpdateClient(data.data, localStorage.getItem('access_token'));
      this.props.setStepOne(data.data);
      this.props.stepComplete(0);
      this.props.goToNext();
    }
  };

  validatePersonId = value => {
    this.setState({
      validpersonIdNumber: true
    });
  }

  validateData = data => {
    this.setState({
      validpersonIdNumber: true
    });

    if (!this.props.isValidPersonId(data.personId, this.props.match.params.countrycode)) {
      this.setState({
        validpersonIdNumber: false
      });

      return false;
    }

    return true;
  }


  saveToSummary = e => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.placeholder,
      },
    });
    this.props.addToSummary(this.state.formData, 'step1');
  };

  changePhone = () => {
    this.setState({
      switchPhone: !this.state.switchPhone,
    });
    const client = this.props.client[0];
    const phone1 = client.phone1;
    const phone2 = client.phone2;
    client.phone1 = phone2;
    client.phone2 = phone1;
    this.props.naevoChangeClientState(client);
  };

  editedPhone = event => {
    const client = this.props.client[0];
    client[event.target.name] = event.target.value;
    this.props.naevoChangeClientState(client);
  };

  render() {
    return (
      <div className="row wrapper">
        <span />
        <div className="col-xs-12 col-sm-6 offset-sm-3 col">
          <div className="card">
            <div className="card-block">
              <FirstStepForm key={this.props.client[0] && this.props.client[0].id}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                validatePersonId={this.validatePersonId}
                changePhone={this.changePhone}
                phoneChanged={this.state.switchPhone}
                validpersonIdNumber={this.state.validpersonIdNumber}
                formatPersonIdFromSeToNoStandart={this.props.formatPersonIdFromSeToNoStandart}
                formatPersonIdFromSeToDkStandart={this.props.formatPersonIdFromSeToDkStandart}
                validSSN={this.state.validSSN}
                editedPhone={this.editedPhone}
                {...this.props}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    scripts: state.scripts,
    client: state.client,
    ctname: state.ctname,
    sourcename: state.sourcename,
    case: state.acase
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToSummary: (object, name) => dispatch(addToSummary(object, name)),
    setStepOne: object => dispatch(setStepOne(object)),
    naevoChangeClientState: client => dispatch(naevoChangeClientState(client)),
    naevoUpdateClient: (formdata, auth) => dispatch(naevoUpdateClient(formdata, auth)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstStepComponent);
