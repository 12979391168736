import React from 'react';
import { connect } from 'react-redux';

import ImportantInfoComponent from '../ImportantInfoComponent';
import SummaryAsideComponent from './SummaryAsideComponent';

function SummaryWithHeaderComponent(props) {
  return (
    <div>
      <ImportantInfoComponent {...props} />
      <SummaryAsideComponent stepone={props.stepone} steptwo={props.steptwo} stepthree={props.stepthree} client={props.client} case={props.acase} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryWithHeaderComponent);
