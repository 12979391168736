import React, { createRef } from 'react';
import EmployeeOverviewComponent from './EmployeeOverviewComponent';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AvailabilityGridComponent from './AvailabilityGridComponent';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  bar: {
    background: '#FF5043',
  },
});
function PartnerOverviewComponent(props) {
  const { classes } = props;
  const { days } = props.overview[0].employees.length > 0 ? props.overview[0].employees[0] : 0;
  const employees = props.overview[0].employees.length > 0 ? props.overview[0].employees : 0;

  return (
    <div>
      <span>
        {/* <span>
          <AvailabilityGridComponent overview={props.overview} articles={props.articles} employees={employees} />
          <br />
        </span> */}
        <AppBar className={classes.bar} position="static" style={{ position: '-webkit-sticky', position: 'sticky', top: '14px' }}>
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              {props.partner.partnerName}
            </Typography>
          </Toolbar>
        </AppBar>
        <EmployeeOverviewComponent finished={props.finished} {...props} />
        {/* {employees.length > 0 &&
          employees.map((e, i) => {
            return (
              <span key={i}>
                <EmployeeOverviewComponent finished={props.finished} employee={e} {...props} />
                <br />
              </span>
            );
          })} */}
      </span>
    </div>
  );
}

PartnerOverviewComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PartnerOverviewComponent);
