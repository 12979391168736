import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Script(props) {
  const replaceList = () => {
    return {
      '[CLIENTNAME]': props.client[0].name,
      '[NAME]': localStorage.getItem('userName'),
      '[CASETYPE]': props.ctname,
      '[SOURCE]': props.sourcename,
      '[BOOKEDDATE]': props.stepthree ? (props.stepthree.date ? props.stepthree.date.split('T')[0] : '') : '',
      '[BOOKEDSLOT]': props.stepthree ? props.stepthree.slot : '',
      '[INSTALLADDRESS]': props.stepone ? props.stepone.installAddressStreet : '',
      '[EMAIL]': props.stepone ? props.stepone.email : '',
      '[ARTICLESUM]': props.articles ? calculateSum() + ',-' : '',
      '[CITY]': props.stepone ? props.stepone.installAddressCity : '',
      '[PHONE1]': props.stepone ? props.stepone.phone1 : '',
      '[ZIP]': props.stepone ? props.stepone.installAddressZip : '',
    };
  };
  // const  = props.script[props.step].replace('[NAME]', 'VEGARD');
  const replaceText = script => {
    let newScript = script;
    const list = replaceList();
    Object.keys(list).forEach(element => {
      if (newScript.indexOf(element) > -1) {
        newScript = newScript.replace(element, '<strong>' + list[element] + '</strong>');
      }
    });
    return newScript;
  };

  const calculateSum = () => {
    let sum = 0;
    props.articles.forEach(a => {
      if (a.state === 1) {
        sum += +a.quantity * (+a.price_C * 1.25);
      }
    });
    return sum;
  };

  const { t } = useTranslation();
  if (!props.script) {
    return <p>Loading...</p>;
  }
  return (
    <fieldset>
      <legend>{t('Script')}</legend>
      {props.client.length > 0 &&
        props.scripts.map((script, i) => (
          <span className="taleManus" key={i}>
            {script.step === props.script && (
              <p
                dangerouslySetInnerHTML={{
                  __html: replaceText(script.text),
                }}
              />
            )}
          </span>
        ))}
    </fieldset>
  );
}
