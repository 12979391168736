import React, { Component } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
class BookingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partners: ['TKE123 Svendsens Parabolservice', 'TKE321 Nygaards Antenneservice'],
      technicians: ['TKE123 Vegard Svendsen', 'TKE321 Amund Nygaard'],
      datePickerDate: '2019-03-01',
      slots: ['08-12', '12-16'],
    };
  }
  handleChange = event => {
    this.logs.unshift('change: ' + event.target.value);

    this.setState({
      value: event.target.value,
      events: this.logs.slice(),
    });
  };

  render() {
    const style = {
      height: '100%',
      background: 'white',
    };
    return (
      <div style={style}>
        <div className="col-xs-12 col-sm-6 example-col">
          <div className="card">
            <div className="card-block">
              <form className="k-form-inline">
                <label className="k-form-field">
                  <span>
                    <Trans>Partner</Trans>
                  </span>
                  {/* <input className="k-textbox" placeholder="Your Name" /> */}
                  <DropDownList data={this.state.partners} />
                </label>
                <label className="k-form-field">
                  <span>
                    <Trans>Technician</Trans>
                  </span>
                  {/* <input className="k-textbox" placeholder="Your Last Name" /> */}
                  <DropDownList data={this.state.technicians} />
                </label>
                <label className="k-form-field">
                  <span>
                    <Trans>Booked Date</Trans>
                  </span>
                  {/* <input className="k-textbox" placeholder="Your Last Name" /> */}
                  <DatePicker />
                </label>
                <label className="k-form-field">
                  <span>
                    <Trans>Booked Slot</Trans>
                  </span>
                  {/* <input className="k-textbox" placeholder="Your Last Name" /> */}
                  <DropDownList data={this.state.slots} />
                </label>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BookingComponent)
);
