import React from 'react';
import { Grid } from '@material-ui/core';
// import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
// import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  bar: {
    background: '#FF5043',
    height: '108px',
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

function ImportantInfoComponent(props) {
  const { t } = useTranslation();
  const { classes } = props;
  return (
    <div>
      <AppBar className={classes.bar} position="static">
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container spacing={16} className={classes.bar} alignItems="center" direction="row" justify="space-evenly">
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <div>
                  <small>{t('Case ID')}</small>
                  <br />
                  <strong>{props.acase.id}</strong>
                </div>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <div>
                  <small>{t('Source')}</small>
                  <br />
                  <span>
                      {props.sources.map((s, i2) => (
                        <span key={i2}>{s.id === props.acase.sourceId && <strong>{s.name}</strong>}</span>
                      ))}
                  </span>
                </div>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <small>{t('Status')}</small>
                <br />
                <strong>{props.acase.stateName}</strong>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
}
ImportantInfoComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImportantInfoComponent);
