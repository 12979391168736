import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SummaryStepThreeComponent(props) {
  const { t } = useTranslation();
  return (
    <div>
      {props.stepthree.employeeid && (
        <span>
          <fieldset>
            <legend>
              {/* <Trans>Quality Assurance</Trans> */}
              {t('Booking details')}
            </legend>
            {props.stepthree.date && (
              <span>
                <p>
                  <strong>{t('Date')}</strong>
                </p>
                {props.stepthree.date && props.stepthree.date.split('T')[0]}
              </span>
            )}
            {props.stepthree.slot && (
              <span>
                <p>
                  <strong>{t('Slot')}</strong>
                </p>
                {props.stepthree.slot}
              </span>
            )}
            {/* KBS */}
            {props.stepthree.kbs && (
              <span>
                <p>
                  <strong>{t('KBS')}</strong>
                </p>
                {props.stepthree.kbs}
              </span>
            )}
          </fieldset>
        </span>
      )}
    </div>
  );
}
