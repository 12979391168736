import React from 'react';
import logo from '../Inselo Logo white.png';
import no from '../img/no.png';
import se from '../img/se.png';
import dk from '../img/dk.png';
import { Grid } from '@material-ui/core';
import a0 from '../Auth/AuthService';

export default function CountrySelect(props) {
  const goToCountry = country => {
    if (localStorage.getItem('i18nextLng') != country) {
      a0.logout();
    }
    // props.history.replace('/' + country + '/start');
    window.location.href = '/' + country + '/start';
  };
  const logout = () => {
    a0.logout();
    window.location.href = '/';
  };
  return (
    <div style={{ height: '100vh', width: '100%', background: '#FF5043' }}>
      <div style={{ position: 'absolute', top: '300px', width: '99%' }}>
        <Grid container spacing={8}>
          <Grid item md={12} style={{ textAlign: 'center' }}>
            <img src={logo} alt="logo" width="300px" />
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item md={3} />
          <Grid item md={2}>
            <img src={no} alt="NORWAY" height="100px" style={{ cursor: 'pointer' }} onClick={() => goToCountry('no')} />
          </Grid>
          <Grid item md={2}>
            <img src={se} alt="SWEDEN" height="100px" style={{ cursor: 'pointer' }} onClick={() => goToCountry('se')} />
          </Grid>
          <Grid item md={2}>
            <img src={dk} alt="DENMARK" height="100px" style={{ cursor: 'pointer' }} onClick={() => goToCountry('dk')} />
          </Grid>
        </Grid>
        <br />
        <br />
        <br />

        {localStorage.getItem('isLoggedIn') && (
          <span>
            <Grid container spacing={8}>
              <Grid item md={4} />
              <Grid item md={4} style={{ textAlign: 'center' }}>
                <p>
                  Logged in as <strong>{localStorage.getItem('userEmail')}</strong> on{' '}
                  <strong>{localStorage.getItem('i18nextLng').toUpperCase()}</strong>
                </p>
              </Grid>
              <Grid item md={4} />
            </Grid>
            <Grid container spacing={8}>
              <Grid item md={4} />
              <Grid item md={4} style={{ textAlign: 'center' }}>
                {/* <a href="javascript:void(1)" onClick={logout}>
                  Logout
                </a> */}
                <button className="buttonLink" type="button" onClick={logout}>
                  {/* <Trans>Logout</Trans> */}
                  Logout
                </button>
              </Grid>
              <Grid item md={4} />
            </Grid>
          </span>
        )}
      </div>
    </div>
  );
}
