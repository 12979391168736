import React from 'react';
import { Input } from '@progress/kendo-react-inputs';
export default function TextFieldComponent(props) {
  const selected = props.selected.find(el => el.question === props.question.question_id);
  return (
    <div>
      <span>
        <label htmlFor={'text' + props.question.question_id} style={{ fontSize: '18px' }}>
          {/* {props.question.question_script} */}
        </label>
        <Input
          label="Svar"
          name={'text' + props.question.question_id}
          id={'text' + props.question.question_id}
          onBlur={props.textChange}
          data-qid={props.question.question_id}
          data-qtext={props.question.question_text}
          data-custom1={props.question.custom1}
          defaultValue={selected ? selected.atext : ''}
          key={selected ? selected.question : 0}
        />
      </span>
    </div>
  );
}
