import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import Truncate from 'react-truncate';
import Tooltip from '@material-ui/core/Tooltip';

import { getStateColor } from '../../../functions';

const styles = theme => ({
  card: {
    minWidth: 275,
    background: 'lightgrey',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  redText: {
    color: 'red',
  },
  bar: {
    background: '#FF5043',
  },
  tooltip: {
    fontSize: '18px',
  },
});
function BookedSlotComponent(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const calculateSlotSize = (totalestimate, estimate) => {
    const base = 25;
    // const add = 25;
    const calc = +estimate / base;
    // const newSize = estimate > base ? base + calc * add : 36;
    const newSize = estimate > 60 ? calc * base : base;
    return { border: '4px solid grey', background: 'lightgrey', height: newSize + 'px', marginTop: '4px' };
  };
  return (
    <div>
      <Paper
        className={classes.paper}
        // style={calculateSlotSize(props.slot.totalSlotTime, props.case.estimatedBookingWork)}
        style={{ border: '4px solid grey', background: 'lightgrey', 'min-height': '25px', 'max-height': '100px', marginTop: '4px' }}
      >
        <Grid container spacing={8}>
        <Grid item md={1} style={{ textAlign: 'left' }}>
            <strong style={{ color: getStateColor(props.case.state) }}>{props.case.stateName}</strong>
          </Grid>
          <Grid item md={1} style={{ textAlign: 'left' }}>
            <strong>{props.case.caseTypeName}</strong>
          </Grid>
          <Grid item md={1} style={{ textAlign: 'center' }}>
            <strong>{props.case.source}</strong>
          </Grid>
          <Grid item md={1} style={{ textAlign: 'center' }}>
            <strong>{props.case.id}</strong>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center' }}>
            <strong>{props.case.address}</strong>
          </Grid>
          <Grid item md={4}>
            <Tooltip title={props.case.orderComment} classes={{ tooltip: classes.tooltip }} placement="top">
              <i>
                <Truncate lines={1} width={300}>
                  {props.case.orderComment}
                </Truncate>
              </i>
            </Tooltip>
          </Grid>
          <Grid item md={1} style={{ textAlign: 'right' }}>
            <strong className={props.case.estimatedBookingWork > props.slot.totalSlotTime ? 'redText' : ''}>{props.case.estimatedBookingWork}</strong>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

BookedSlotComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookedSlotComponent);
