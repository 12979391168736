import GoldenLayout from 'golden-layout';
import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import PropTypes from 'prop-types';

import MainWindowComponent from './components/MainWindow/MainWindowComponent';
import MapComponent from './components/SideWindow/Map/MapComponent';
// import SummaryComponent from './components/Summary/SummaryComponent';
import SummaryWithHeaderComponent from './components/Summary/SummaryWithHeaderComponent';
import InfoComponent from './components/SideWindow/Info/InfoComponent';
// import CaseComponent from './components/SideWindow/Case/CaseComponent';
// import CustomerInfoComponent from './components/SideWindow/CustomerInfo/CustomerInfoComponent';
// import ContactInfoComponent from './components/SideWindow/ContactInfo/ContactInfoComponent';
import BookingComponent from './components/SideWindow/Booking/BookingComponent';
import HistoryComponent from './components/SideWindow/History/HistoryComponent';
import OrderComComponent from './components/SideWindow/Comment/OrderComComponent';
import InternalComComponent from './components/SideWindow/Comment/InternalComComponent';
import ArticlesComponent from './components/SideWindow/Articles/ArticlesComponent';
import SmsComponent from './components/SideWindow/Sms/SmsComponent';
import SideWindowHeaderComponent from './components/SideWindow/SideWindowHeaderComponent';
import CaseCardComponent from './components/SideWindow/CaseCard/CaseCardComponent';
import { withTranslation, Trans } from 'react-i18next';

// import { connect } from 'react-redux';
// import { simpleAction } from './actions/actions';

class GoldenLayoutWrapper extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }
  logout() {
    this.props.auth.logout();
  }
  componentDidMount() {
    const config = {
      settings: {
        hasHeaders: false,
        constrainDragToContainer: true,
        reorderEnabled: true,
        selectionEnabled: false,
        popoutWholeStack: true,
        blockedPopoutsThrowError: true,
        closePopoutsOnUnload: false,
        showPopoutIcon: true,
        showMaximiseIcon: false,
        showCloseIcon: false,
      },
      dimensions: {
        borderWidth: 1,
      },
      labels: {
        close: <Trans>Close</Trans>,
        maximise: 'maximise',
        minimise: 'minimise',
        popout: 'open in new window',
      },
      content: [
        {
          type: 'row',
          isClosable: false,
          width: 100,
          content: [
            {
              type: 'column',
              title: 'stack a',
              isClosable: false,
              content: [
                {
                  title: 'Main Window',
                  type: 'react-component',
                  component: 'MainWindowComponent',
                  isClosable: false,
                },
              ],
            },
            // {
            //   type: 'column',
            //   width: 0,
            //   content: [
            //     {
            //       type: 'column',
            //       isClosable: false,
            //       width: 100,
            //       content: [
            //         {
            //           type: 'stack',
            //           title: 'Screen 2',
            //           isClosable: false,
            //           content: [
            //             {
            //               type: 'row',
            //               isClosable: false,
            //               content: [
            //                 {
            //                   type: 'stack',
            //                   content: [
            //                     {
            //                       title: 'Info',
            //                       type: 'react-component',
            //                       component: 'InfoComponent',
            //                       isClosable: false,
            //                     },
            //                     {
            //                       title: 'CaseCard',
            //                       type: 'react-component',
            //                       component: 'CaseCardComponent',
            //                       isClosable: false,
            //                     },
            //                   ],
            //                 },
            //                 {
            //                   type: 'column',
            //                   isClosable: false,
            //                   content: [
            //                     {
            //                       title: 'Articles',
            //                       type: 'react-component',
            //                       component: 'ArticlesComponent',
            //                       isClosable: false,
            //                     },
            //                     {
            //                       type: 'stack',
            //                       content: [
            //                         {
            //                           title: 'Order Communication',
            //                           type: 'react-component',
            //                           component: 'OrderComComponent',
            //                           isClosable: false,
            //                         },
            //                         {
            //                           title: 'Internal Communication',
            //                           type: 'react-component',
            //                           component: 'InternalComComponent',
            //                           isClosable: false,
            //                         },
            //                       ],
            //                     },
            //                   ],
            //                 },
            //                 {
            //                   type: 'column',
            //                   isClosable: false,
            //                   content: [
            //                     {
            //                       type: 'row',
            //                       content: [
            //                         {
            //                           title: 'Summary',
            //                           type: 'react-component',
            //                           component: 'SummaryWithHeaderComponent',
            //                           isClosable: false,
            //                         },
            //                       ],
            //                     },
            //                   ],
            //                 },
            //               ],
            //             },
            //           ],
            //         },
            //       ],
            //     },
            //   ],
            // },
          ],
        },
      ],
    };
    const screen2Config = {
      type: 'column',
      isClosable: false,
      width: 0,
      cssClass: 'secondary-screen',
      content: [
        {
          type: 'stack',
          title: 'Screen 2',
          isClosable: false,
          content: [
            {
              type: 'row',
              isClosable: false,
              content: [
                {
                  type: 'stack',
                  content: [
                    {
                      title: 'Info',
                      type: 'react-component',
                      component: 'InfoComponent',
                      isClosable: false,
                    },
                    {
                      title: 'CaseCard',
                      type: 'react-component',
                      component: 'CaseCardComponent',
                      isClosable: false,
                    },
                  ],
                },
                {
                  type: 'column',
                  isClosable: false,
                  content: [
                    {
                      title: 'Articles',
                      type: 'react-component',
                      component: 'ArticlesComponent',
                      isClosable: false,
                    },
                    {
                      type: 'stack',
                      content: [
                        {
                          title: 'Order Communication',
                          type: 'react-component',
                          component: 'OrderComComponent',
                          isClosable: false,
                        },
                        {
                          title: 'Internal Communication',
                          type: 'react-component',
                          component: 'InternalComComponent',
                          isClosable: false,
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'column',
                  isClosable: false,
                  content: [
                    {
                      type: 'row',
                      content: [
                        {
                          title: 'Summary',
                          type: 'react-component',
                          component: 'SummaryWithHeaderComponent',
                          isClosable: false,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };

    function wrapComponent(Component, store, history, match, auth) {
      class Wrapped extends React.Component {
        render() {
          return (
            <Suspense fallback={<div>Loading...</div>}>
              <Provider store={store}>
                <Component history={history} auth={auth} match={match} screen2={screen2Config} {...this.props} />
              </Provider>
            </Suspense>
          );
        }
      }
      return Wrapped;
    }

    setTimeout(async () => {
      window.React = React;
      window.ReactDOM = ReactDOM;
      let layout,
        savedState = localStorage.getItem('savedState');
      if (savedState !== null) {
        layout = new GoldenLayout(JSON.parse(savedState));
      } else {
        layout = new GoldenLayout(config);
      }
      layout.registerComponent(
        'MainWindowComponent',
        wrapComponent(MainWindowComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );
      layout.registerComponent('MapComponent', wrapComponent(MapComponent, this.props.store, this.props.history, this.props.match, this.props.auth));
      layout.registerComponent(
        'SummaryWithHeaderComponent',
        wrapComponent(SummaryWithHeaderComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );
      layout.registerComponent(
        'InfoComponent',
        wrapComponent(InfoComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );
      layout.registerComponent(
        'BookingComponent',
        wrapComponent(BookingComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );
      layout.registerComponent(
        'HistoryComponent',
        wrapComponent(HistoryComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );
      layout.registerComponent(
        'OrderComComponent',
        wrapComponent(OrderComComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );
      layout.registerComponent(
        'InternalComComponent',
        wrapComponent(InternalComComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );
      layout.registerComponent(
        'ArticlesComponent',
        wrapComponent(ArticlesComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );
      layout.registerComponent(
        'CaseCardComponent',
        wrapComponent(CaseCardComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );
      layout.registerComponent('SmsComponent', wrapComponent(SmsComponent, this.props.store, this.props.history, this.props.match, this.props.auth));
      layout.registerComponent(
        'SideWindowHeaderComponent',
        wrapComponent(SideWindowHeaderComponent, this.props.store, this.props.history, this.props.match, this.props.auth)
      );

      layout.on('itemCreated', function (item) {
        if (item.config.cssClass) {
          item.element.addClass(item.config.cssClass);
        }
      });

      layout.init();

      // POPOUT SCREEN 2
      if (layout.root) {
        // if (sessionStorage.getItem('screen2')) {
        //   layout.root.contentItems[0].removeChild(layout.root.contentItems[0].contentItems[1]);
        // }
        // if (sessionStorage.getItem('screen2') && sessionStorage.getItem('screenClosed')) {
        // layout.root.contentItems[0].addChild(screen2Config);
        // }
        // setTimeout(() => {
        //   layout.createPopout(screen2Config);
        // }, 200);
        // if (layout.root.contentItems[0].contentItems[1]) layout.root.contentItems[0].contentItems[1].popout();
      }

      layout.on('windowOpened', () => {
        sessionStorage.setItem('screen2', true);
      });

      layout.on('windowClosed', () => {
        sessionStorage.removeItem('screen2');
      });

      // SAVE STATE
      // setTimeout(() => {
      //   if (layout.isInitialised) {
      //     layout.on('stateChanged', function() {
      //       const state = JSON.stringify(layout.toConfig());
      //       localStorage.setItem('savedState', state);
      //     });
      //   } else {
      //     return;
      //   }
      // }, 1000);

      window.addEventListener('resize', () => {
        layout.updateSize();
      });
      // l.then(layout => {
    });
  }

  render() {
    return (
      <div>
        <div className="goldenLayout" ref={input => (this.layout = input)} />
        {/* <div>
            <input type="button" value="Logout" onClick={this.logout.bind(this)} />
        </div> */}
      </div>
    );
  }
}

// GoldenLayoutWrapper.contextTypes = {
//   store: PropTypes.object.isRequired,
// };

// const mapStateToProps = state => ({
//   ...state,
// });

// const mapDispatchToProps = dispatch => ({
//     simpleAction: () => dispatch(simpleAction()),
// });

export default withTranslation()(GoldenLayoutWrapper);
