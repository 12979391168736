import React from 'react';

export default function CheckBoxComponent(props) {
  const selected = props.selected.filter(el => el.question === props.question.question_id);
  return (
    <div>
      {props.answers.map((a, i) => (
        <span key={i}>
          {a.question_id === props.question.question_id && (
            <span>
              <input
                type="checkbox"
                name={'checkbox' + a.answer_id}
                id={'checkbox' + a.answer_id}
                data-qid={props.question.question_id}
                data-aid={a.answer_id}
                data-script={a.script}
                data-qtext={props.question.question_text}
                data-atext={a.text}
                data-articleid={a.articleId}
                className="k-checkbox"
                checked={selected.length > 0 ? selected.some(x => x.answer === a.answer_id) : false}
                onChange={props.checkboxChange}
              />
              <label htmlFor={'checkbox' + a.answer_id} className="k-checkbox-label" style={{ fontSize: '14px' }}>
                <strong>{a.text}</strong>
              </label>
            </span>
          )}
        </span>
      ))}
    </div>
  );
}
