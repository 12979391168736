import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  chip: {
    margin: theme.spacing.unit,
  },
});
class SuggestedPartnersListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: '',
    };
  }

  handleListItemClick = (event, index, partnerid, installerid) => {
    this.setState({ selectedIndex: index });
    this.props.getOverview(partnerid, installerid);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <fieldset>
          <legend>
            <Trans>Suggested partners and installers</Trans>
          </legend>
          <div>
            <List component="nav">
              {this.props.geopostalcode.details.map((detail, i) => (
                <span key={i}>
                  <span>
                    {this.props.cc === 'se' &&
                      detail.installers &&
                      detail.installers.map((installer, ii) => (
                        <span key={ii}>
                          <ListItem
                            button
                            selected={this.state.selectedIndex === ii}
                            onClick={e => this.handleListItemClick(e, ii, installer.partnerId, installer.id)}
                          >
                            <ListItemText primary={installer.partner} secondary={installer.name} />
                          </ListItem>
                        </span>
                      ))}
                    {this.props.cc === 'no' && (
                      <span>
                        {detail.partner.map((p, index) => (
                          <ListItem button selected={this.state.selectedIndex === index} onClick={e => this.handleListItemClick(e, index, p.id, '')}>
                            <ListItemText primary={p.name} />
                          </ListItem>
                        ))}
                      </span>
                    )}
                  </span>

                  {detail.partner.length > 1 && <Divider />}
                </span>
              ))}
            </List>
          </div>
        </fieldset>
      </div>
    );
  }
}

SuggestedPartnersListComponent.propTypes = {
  geopostalcode: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired,
  getOverview: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    geopostalcode: state.geopostalcode,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SuggestedPartnersListComponent)
  )
);
