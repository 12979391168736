import axios from 'axios';
// import { mockcase } from '../mockcase';
import { questionsFetchData, answersFetchData, scriptsFetchData } from './sheetsu';
import { caseTypeNameSuccess, sourceNameSuccess, getAnsweredQuestionsByCase } from './other';
import * as Moment from 'moment';
import { geoCheckPostalCode } from './geo';
import { getNaevoApiUrl, getNaevoApiToken } from '../functions';
import a0 from '../Auth/AuthService';
// import {
//   Spinner, // The React component
//   pendingTasksReducer, // The redux reducer
//   pendingTask, // The action key for modifying loading state
//   begin, // The action value if a "long" running task begun
//   end, // The action value if a "long" running task ended
//   endAll, // The action value if all running tasks must end
// } from 'react-redux-spinner';

const token = getNaevoApiToken();
const api_url = getNaevoApiUrl();

const countryCode = () => {
  const arr = window.location.pathname.split('/');
  return arr[1];
};

// ACTIONS
export function naevoGetCasesSuccess(cases) {
  return {
    type: 'NAEVO_GET_CASES_SUCCESS',
    cases,
  };
}

export function naevoGetCaseSuccess(acase) {
  return {
    type: 'NAEVO_GET_CASE_SUCCESS',
    acase,
  };
}

export function naevoGetSourcesSuccess(sources) {
  return {
    type: 'NAEVO_GET_SOURCES_SUCCESS',
    sources,
  };
}

export function naevoGetArticlesSuccess(articles) {
  return {
    type: 'NAEVO_GET_ARTICLES_SUCCESS',
    articles,
  };
}

export function naevoGetAllArticlesSuccess(allArticles) {
  return {
    type: 'NAEVO_GET_ALL_ARTICLES_SUCCESS',
    allArticles,
  };
}

export function naevoGetClientSuccess(client) {
  return {
    type: 'NAEVO_GET_CLIENT_SUCCESS',
    client,
  };
}

export function naevoUpdateClientSuccess(client) {
  return {
    type: 'NAEVO_UPDATE_CLIENT_SUCCESS',
    client,
  };
}

export function naevoChangeClientStateSuccess(client) {
  return {
    type: 'NAEVO_CHANGE_CLIENT_STATE_SUCCESS',
    client,
  };
}

export function naevoGetInstallersSuccess(installers) {
  return {
    type: 'NAEVO_GET_INSTALLERS_SUCCESS',
    installers,
  };
}

export function naevoGetPartnersSuccess(partners) {
  return {
    type: 'NAEVO_GET_PARTNERS_SUCCESS',
    partners,
  };
}

export function naevoGetOverviewByPartnerSuccess(overview) {
  return {
    type: 'NAEVO_GET_OVERVIEW_BY_PARTNER_SUCCESS',
    overview,
  };
}
export function naevoGetOverviewByPartnerAndInstallerSuccess(overview) {
  return {
    type: 'NAEVO_GET_OVERVIEW_BY_PARTNER_AND_INSTALLER_SUCCESS',
    overview,
  };
}

export function naevoGetOverviewByRegionSuccess(overviewregion) {
  return {
    type: 'NAEVO_GET_OVERVIEW_BY_REGION_SUCCESS',
    overviewregion,
  };
}

export function naevoSetCaseRestingSuccess(resting) {
  return {
    type: 'NAEVO_SET_CASE_RESTING',
    resting,
  };
}

export function naevoSetCaseCancelledSuccess(cancelled) {
  return {
    type: 'NAEVO_SET_CASE_CANCELLED',
    cancelled,
  };
}

export function naevoSetCaseStateAsPhoneCallSuccess(phonecallno) {
  return {
    type: 'NAEVO_SET_CASE_STATE_AS_PHONE_CALL',
    phonecallno,
  };
}

export function naevoSetCaseKbsSuccess(kbs) {
  return {
    type: 'NAEVO_SET_CASE_KBS',
    kbs,
  };
}

export function naevoPostCommentSuccess(comment) {
  return {
    type: 'NAEVO_POST_COMMENT_SUCCESS',
    comment,
  };
}

export function naevoPostInternalCommentSuccess(comment) {
  return {
    type: 'NAEVO_POST_INTERNAL_COMMENT_SUCCESS',
    comment,
  };
}

export function naevoBookCaseSuccess(booked) {
  return {
    type: 'NAEVO_BOOK_CASE_SUCCESS',
    booked,
  };
}

export function naevoCheckTaxDeductionSuccess(tax) {
  return {
    type: 'NAEVO_CHECK_TAX_DEDUCTION_SUCCESS',
    tax,
  };
}

export function naevoUpdateTaxDeductionSuccess(tax) {
  return {
    type: 'NAEVO_UPDATE_TAX_DEDUCTION_SUCCESS',
    tax,
  };
}

export function addToSummarySuccess(summary) {
  return {
    type: 'ADD_TO_SUMMARY_SUCCESS',
    summary,
  };
}

export function sendPartnerSmsSuccess(sms) {
  return {
    type: 'SEND_PARTNER_SMS_SUCCESS',
    sms
  };
}

export function sendEmployeeSmsSuccess(sms) {
  return {
    type: 'SEND_EMPLOYEE_SMS_SUCCESS',
    sms
  }
}

// ACTION CREATORS

/**
 * ACTION CREATOR
 * Get All Cases From Naevo Based On source_id And state
 *
 * @export
 * @param {*} source_id ID of source
 * @param {*} state State of case
 * @returns Array of cases
 */
export function naevoGetCases(source_id, state, auth) {
  return dispatch => {
    axios({
      method: 'GET',
      url: `${api_url}/cases?SourceId=${source_id}&State=${state}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
      .then(cases => dispatch(naevoGetCasesSuccess(cases)));
    // const mock = mockcase;
    // dispatch(naevoGetCasesSuccess(mock));
  };
}

/**
 * ACTION CREATOR
 * Get Case From Naevo Based On case_id
 *
 * @export
 * @param {*} case_id ID of case
 * @returns Array of case
 */
export function naevoGetCase(case_id, auth) {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `${api_url}/case?caseId=${case_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async res => {
        console.log(res);
        const result = res.data;
        const client = [res.data.client];
        const articles = [res.data.orderRows];
        // console.log(result);
        await dispatch(naevoGetSources(result, auth));
        await dispatch(naevoGetArticles(articles, auth));
        await dispatch(naevoGetInstallers(client, auth));
        // await dispatch(naevoGetPartners(client, auth));
        await dispatch(naevoCheckTaxDeduction(result.id, auth));
        await dispatch(naevoGetClientSuccess(client));
        // dispatch(formLoadFirstStepForm(client));

        await dispatch(naevoPostInternalCommentSuccess(result.internalComment));
        await dispatch(naevoPostCommentSuccess(result.comment));

        return res.data;
      })
      .then(acase => dispatch(naevoGetCaseSuccess(acase)));
  };
}

/**
 * ACTION CREATOR
 * Get All Sources From Naevo
 *
 * @export
 * @returns Array Of Sources
 */
export function naevoGetSources(casedata, auth) {
  return dispatch => {
    axios({
      method: 'GET',
      url: `${api_url}/sources`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async res => {
        const result = res.data;
        // console.log(result);
        // console.log(casedata[0].sourceId);
        const source = result.find(s => s.id === casedata.sourceId);
        const casetype = source.caseTypes.find(ct => ct.id === casedata.sourceCaseTypeId);
        // fetch questions based on case data
        await dispatch(scriptsFetchData(auth));
        await dispatch(questionsFetchData(casedata.sourceId, casetype.id, auth));
        await dispatch(answersFetchData(casedata.sourceId, casetype.id, auth));
        await dispatch(caseTypeNameSuccess(casetype.name));
        await dispatch(sourceNameSuccess(source.name));
        // dispatch(informationQuestionsFetchData(casedata[0].sourceId, casetype.name, auth));
        // dispatch(mersalgQuestionsFetchData(casedata[0].sourceId, casetype.name, auth));
        return res.data;
      })
      .then(sources => dispatch(naevoGetSourcesSuccess(sources)));
  };
}

/**
 * ACTION CREATOR
 * Get Single Client From Naevo Based On client_id
 *
 * @export
 * @param {*} client_id ID Of Client
 * @returns Object Of Client
 */
export function naevoGetClient(client_id, auth) {
  return dispatch => {
    axios({
      method: 'GET',
      url: `${api_url}/client?clientid=${client_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
      .then(client => dispatch(naevoGetClientSuccess(client)));
  };
}

/**
 * ACTION CREATOR
 * Get Articles For Case
 *
 * @export
 * @param {*} casearticles Case Articles
 * @param {*} auth Auth Token
 * @returns Case Articles Merged With Articles
 */
export function naevoGetArticles(casearticles, auth) {
  return (dispatch, getState) => {
    const stateArticles = getState().articles;
    if (!stateArticles.length > 0) {
      axios({
        method: 'GET',
        url: `${api_url}/articles`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          const all = res.data;
          dispatch(naevoGetAllArticlesSuccess(all));

          const articles = [];
          casearticles[0].forEach(a => {
            all.find(article => {
              if (article.id === a.articleId && a.state === 1) {
                const numberOfElements = casearticles[0].filter(ca => ca.articleId === article.id && ca.state === 1);
                // Merge objects
                const newObj = Object.assign(article, a);
                newObj.quantity = numberOfElements.length;
                articles.push(newObj);
              }
            });
          });
          return articles;
        })
        .then(articles => dispatch(naevoGetArticlesSuccess(articles)));
    } else {
      dispatch(naevoGetArticlesSuccess(stateArticles));
    }
  };
}

/**
 * ACTION CREATOR
 * Update Client State And PUT To API
 *
 * @export
 * @param {*} formdata Form To Update State
 * @param {*} auth Access Token
 * @returns Result Of Update
 */
export function naevoUpdateClient(formdata, auth) {
  return (dispatch, getState) => {
    const client = getState().client[0];
    const acase = getState().acase;
    Object.keys(formdata).forEach(el => {
      client[el] = formdata[el];
    });
    client['caseId'] = acase.id;
    if (client.ssn === '' || client.ssn === undefined || client.ssn === false) client.ssn = '0000';
    axios({
      method: 'PUT',
      url: `${api_url}/client`,
      data: client,
      headers: {
        Authorization: `Bearer ${token}`,
        EmployeeId: `${a0.getNaevoUserId()}`
      },
    })
      .then(res => res.data)
      .then(() => {
        dispatch(naevoGetCase(acase.id, auth));
      });
  };
}

export function naevoChangeClientState(client) {
  return dispatch => {
    const arr = [];
    arr.push(client);
    dispatch(naevoChangeClientStateSuccess(arr));
  };
}

/**
 * ACTION CREATOR
 * Get All Installers
 *
 * @export
 * @param {*} auth Auth Token
 * @returns All Installers
 */
export function naevoGetInstallers(client, auth) {
  return (dispatch, getState) => {
    const installers = getState().installers;
    if (!installers.length > 0) {
      axios({
        method: 'GET',
        url: `${api_url}/installers`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => res.data)
        .then(installers => {
          dispatch(naevoGetPartners(installers, client, auth));
          dispatch(naevoGetInstallersSuccess(installers));
        });
    } else {
      dispatch(naevoGetPartners(installers, client, auth));
      dispatch(naevoGetInstallersSuccess(installers));
    }
  };
}

/**
 * ACTION CREATOR
 * Get All Partners
 *
 * @export
 * @param {*} auth Auth Token
 * @returns All Partners
 */
export function naevoGetPartners(installers, client, auth) {
  return (dispatch, getState) => {
    const partners = getState().partners;
    if (!partners.length > 0) {
      axios({
        method: 'GET',
        url: `${api_url}/partners`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => res.data)
        .then(partners => {
          dispatch(geoCheckPostalCode(installers, partners, countryCode(), client[0].installAddressZip, auth));
          dispatch(naevoGetPartnersSuccess(partners));
        });
    } else {
      dispatch(geoCheckPostalCode(installers, partners, countryCode(), client[0].installAddressZip, auth));
      dispatch(naevoGetPartnersSuccess(partners));
    }
  };
}

/**
 * ACTION CREATOR
 * Get Overview By Partner ID And Date Range
 *
 * @export
 * @param {*} partnerid Partner ID
 * @param {*} fromdate FromDate
 * @param {*} todate ToDate
 * @param {*} auth Auth Token
 * @returns Overview
 */
export function naevoGetOverviewByPartner(partnerid, fromdate, todate, auth) {
  fromdate = Moment(fromdate).format('YYYY-MM-DD hh:mm:ss');
  todate = Moment(todate).format('YYYY-MM-DD hh:mm:ss');
  return dispatch => {
    axios({
      method: 'GET',
      url: `${api_url}/bookingOverViewByPartner?PartnerId=${partnerid}&DateFrom=${fromdate}&DateTo=${todate}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
      .then(overview => dispatch(naevoGetOverviewByPartnerSuccess(overview)));
  };
}
/**
 * ACTION CREATOR
 * Get Overview By Partner ID, InstallerId And Date Range
 *
 * @export
 * @param {*} partnerid Partner ID
 * @param {*} installerid Installer ID
 * @param {*} fromdate FromDate
 * @param {*} todate ToDate
 * @param {*} auth Auth Token
 * @returns Overview
 */
export function naevoGetOverviewByPartnerAndInstaller(partnerid, installerid, fromdate, todate, auth) {
  fromdate = Moment(fromdate).format('YYYY-MM-DD hh:mm:ss');
  todate = Moment(todate).format('YYYY-MM-DD hh:mm:ss');
  return dispatch => {
    axios({
      method: 'GET',
      url: `${api_url}/bookingOverViewByPartner?PartnerId=${partnerid}&DateFrom=${fromdate}&DateTo=${todate}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        let overview = res.data;
        overview.map(o => {
          o.employees = o.employees.filter(e => {
            if (e.employeeId === installerid) {
              return e;
            }
          });
        });
        return overview;
      })
      .then(overview => dispatch(naevoGetOverviewByPartnerAndInstallerSuccess(overview)));
  };
}

export function naevoGetOverviewByRegion(regionid, fromdate, todate, auth) {
  fromdate = Moment(fromdate).format('YYYY-MM-DD hh:mm:ss');
  todate = Moment(todate).format('YYYY-MM-DD hh:mm:ss');
  return dispatch => {
    axios({
      method: 'GET',
      url: `${api_url}/bookingOverViewByRegion?RegionId=${regionid}&DateFrom=${fromdate}&DateTo=${todate}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
      .then(overview => dispatch(naevoGetOverviewByRegionSuccess(overview)));
  };
}

/**
 * ACTION CREATOR
 * Set Current Case Resting Until Date
 *
 * @export
 * @param {*} date Case Is Resting Until This Date
 * @param {*} auth Auth token
 * @returns
 */
export function naevoSetCaseResting(comment, date, answerData, auth) {
  return (dispatch, getState) => {
    const acase = getState().acase;
    axios({
      method: 'PUT',
      url: `${api_url}/putCaseOnRest`,
      data: {
        caseId: acase.id,
        followUpdate: date,
        comment: comment,
        employeeId: answerData.employeeId,
        feedbackAnswerIds: answerData.answerIds
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
      .then(resting => dispatch(naevoSetCaseRestingSuccess(resting)));
  };
}

export function naevoSetCaseStateAsPhoneCall(phonecallno, auth) {
  return (dispatch, getState) => {
    const acase = getState().acase;
    axios({
      method: 'PUT',
      url: `${api_url}/setStatePhoneCall`,
      data: {
        caseId: acase.id,
        phoneNo: phonecallno
      },
      headers: {
        Authorization: `Bearer ${token}`,
        EmployeeId: `${a0.getNaevoUserId()}`
      },
    })
      .then(res => res.data)
      .then(phonecallno => dispatch(naevoSetCaseStateAsPhoneCallSuccess(phonecallno)));
  };
}

export function naevoSetCaseCancelled(cancelData, auth) {
  return (dispatch, getState) => {
    const acase = getState().acase;
    axios({
      method: 'PUT',
      url: `${api_url}/cancelCase`,
      data: {
        caseId: acase.id,
        comment: cancelData.comment,
        employeeId: cancelData.employeeId,
        feedbackAnswerIds: cancelData.answerIds
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        dispatch(naevoPostComment('InternalComment', cancelData.comment, auth));

        return res.data;
      })
      .then(cancelled => dispatch(naevoSetCaseCancelledSuccess(cancelled)));
  };
}

export function naevoSetCaseKbs(installerid, auth) {
  return (dispatch, getState) => {
    const acase = getState().acase;
    return axios({
      method: 'PUT',
      url: `${api_url}/caseCourierSelfbooking?caseId=${acase.id}&installerId=${installerid}`,
      headers: {
        Authorization: `Bearer ${token}`,
        EmployeeId: `${a0.getNaevoUserId()}`
      },
    })
      .then(res => res.data)
      .then(kbs => dispatch(naevoSetCaseKbsSuccess(kbs)));
  };
}

export function naevoPostComment(type, comment, auth) {
  return (dispatch, getState) => {
    const caseid = getState().acase.id;
    axios({
      method: 'POST',
      url: `${api_url}/caseComment`,
      data: {
        caseId: caseid,
        naevoUserID: localStorage.getItem('naevoUser'),
        comment: comment,
        type: type,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
      .then(comment => {
        console.log(type);
        console.log(comment);
        if (type === 'Comment')
          dispatch(naevoPostCommentSuccess(comment));
        if (type === 'InternalComment')
          dispatch(naevoPostInternalCommentSuccess(comment));
      }
      );
  };
}
export function naevoBookCase(object, auth) {
  return dispatch => {
    if (object.kbs) {
      return dispatch(naevoSetCaseKbs(object.employeeid, auth));
    } else {
      let slott = 0;
      switch (object.slot) {
        case '08-13':
          slott = 1;
          break;
        case '12-17':
          slott = 2;
          break;
        case '08-12':
          slott = 3;
          break;
        case '12-15':
          slott = 4;
          break;
        case '15-18':
          slott = 5;
          break;
        case '18-21':
          slott = 6;
          break;
        case '12-16':
          slott = 7;
          break;
        case '12-18':
          slott = 8;
          break;
        default:
          break;
      }
      return axios({
        method: 'POST',
        url: `${api_url}/bookCase`,
        data: {
          caseId: object.caseid,
          employeeId: object.employeeid,
          slot: slott,
          installDate: object.date,
          answerId: object.slaAnswer,
          answerData: object.answerData,
          deleteLead: true
        },
        headers: {
          Authorization: `Bearer ${token}`,
          EmployeeId: `${a0.getNaevoUserId()}`
        },
      })
        .then(res => res.data)
        .then(booked => dispatch(naevoBookCaseSuccess(booked)))
  }
  };
}

export function naevoCheckTaxDeduction(caseid, auth) {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `${api_url}/caseTaxDeducation?caseId=${caseid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
      .then(tax => dispatch(naevoCheckTaxDeductionSuccess(tax)));
  };
}

export function naevoUpdateTaxDeduction(object, auth, refreshCaseData) {
  return (dispatch, getState) => {
    const caseid = getState().acase.id;
    axios({
      method: 'PUT',
      url: `${api_url}/UpadteCaseTaxDeducation`,
      data: object,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
      .then(() => dispatch(naevoCheckTaxDeduction(caseid, auth)))
      .then(() => {
        if (refreshCaseData) {
          dispatch(naevoGetCase(caseid, auth));
        }
      })
  };
}

export function naevoSendPartnerSMS(partnerId) {
  return (dispatch, getState) => {
    const caseId = getState().acase.id;
    return axios({
      method: 'GET',
      url: `${api_url}/sendPartnerSMS/${partnerId}/${caseId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(res => res.data)
      .then(sms => dispatch(sendPartnerSmsSuccess(sms)));
  }
}

export function naevoSendEmployeeSMS(employeeId) {
  return (dispatch, getState) => {
    const caseId = getState().acase.id;
    return axios({
      method: 'GET',
      url: `${api_url}/sendEmployeeSMS/${employeeId}/${caseId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(res => res.data)
      .then(sms => dispatch(sendEmployeeSmsSuccess(sms)));
  }
}

export function addToSummary(object, name) {
  return (dispatch, getState) => {
    let { summary } = getState();

    if (summary.length > 0) {
      let found = summary.find(el => el.name === name);
      if (found) {
        found = object;
      } else {
        summary.push(object);
      }
      // summary.find(el => {
      //   console.log(el.name);
      //   if (el.name === name) {
      //     console.log('found');
      //     summary[name] = object;
      //     found = true;
      //     // } else {
      //   }
      //   if (!found) {
      //     console.log('not found');
      //     summary.push(object);
      //   }
      // });
    } else {
      summary.push(object);
      console.log(summary);
    }
    // summary[name] = {
    //   object,
    // };
    dispatch(addToSummarySuccess(summary));
  };
}
