import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useTranslation } from 'react-i18next';

// import './SlaDialog.css';
import { Grid } from '@material-ui/core';

export default function SlaDialog(props) {
  const { t } = useTranslation();
  const fq = props.acase.feedbackQuestions;
  const setAnswer = e => {
    props.slaAnswer(e);
  };
  return (
    <div>
      <Dialog title={t('Outside SLA')} onClose={props.toggleDialog}>
        <Grid container spacing={8}>
          <Grid item md={12} style={{ textAlign: 'center' }}>
            {fq
              .filter(qq => qq.specialTrigger === 1)
              .map((q, i) => (
                <span key={i}>
                  <h3>{q.question}</h3>
                  <select name="slaAnswers" id="a" onChange={setAnswer}>
                    <option value="">{t('Select')}</option>
                    {q.answers.map((a, ii) => (
                      <option key={ii} value={a.id}>
                        {a.answer}
                      </option>
                    ))}
                  </select>
                </span>
              ))}
          </Grid>
        </Grid>

        <DialogActionsBar>
          <button className="k-button" onClick={props.toggleDialog}>
            {t('Back')}
          </button>
          <button className="k-button" onClick={props.bookCase} disabled={!props.selectedAnswer}>
            <span style={{ color: 'red' }}>{t('Save Booking')}</span>
          </button>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
}
